import * as firebase from "firebase/app"
import "firebase/firestore"
import "firebase/functions"
import "firebase/auth"
import { log } from "./Logging"

export interface FirebaseDoc {
    id?: string
}

log.debug("Initializing firebase.")
const FIREBASE_PROD = process.env.REACT_APP_FIRE_PROD === "true"
log.debug(`Firebase prod: ${FIREBASE_PROD}`)

interface FirebaseOptions {
    name: string
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
}

const makeConfig = (
    name: string,
    messagingSenderId: string,
    apiKey: string,
    appId: string,
): FirebaseOptions => ({
    name,
    apiKey,
    authDomain: `clickcity-${name}.firebaseapp.com`,
    databaseURL: `https://clickcity-${name}.firebaseio.com`,
    projectId: `clickcity-${name}`,
    storageBucket: `clickcity-${name}.appspot.com`,
    messagingSenderId,
    appId,
})
const prodConfig = makeConfig(
    "e627c",
    "933932724595",
    "AIzaSyC721S24vrt4zZA0eP1HsCvFWePdEZANE8",
    "1:933932724595:web:86577db05efc2c5a",
)
const devConfig = makeConfig(
    "dev",
    "802193738758",
    "AIzaSyAt48EOYJhk4UffhK3Ows74AuGAEeD0g-g",
    "1:802193738758:web:9b85b4e722f32801678787",
)
export let fireBaseOptionsInUse: FirebaseOptions

const initializeEnv = () => {
    let config
    if (FIREBASE_PROD) {
        config = prodConfig
    } else {
        config = devConfig
    }
    log.debug(`Firebase using: ${config.name} env`)
    firebase.initializeApp(config)
}

initializeEnv()

export const fbdb = firebase.firestore()
export const fbFunctions = () => firebase.functions()
export const fbAuth = () => firebase.auth()

export const studentCollection = () => fbdb.collection("student")
export const studentResultsCollection = () => fbdb.collection("student-results")
export const activityProgressCollection = () =>
    fbdb.collection("all-activity-progress")
export const classCollection = () => fbdb.collection("class")
export const portalUserCollection = () => fbdb.collection("portal-user")
export const userInviteCollection = () => fbdb.collection("user-invite")
export const schoolCollection = () => fbdb.collection("school")

export type QuerySnapshot = firebase.firestore.QuerySnapshot
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot
export const emailAuthProviderId = firebase.auth.EmailAuthProvider.PROVIDER_ID
export type FBTimestamp = firebase.firestore.Timestamp
export const FBTimestampNow = () => firebase.firestore.Timestamp.now()

export class FbUtils {
    static queryToDocs = (queryDocs: QuerySnapshot): FirebaseDoc[] => {
        if (queryDocs.empty) {
            return []
        }
        return queryDocs.docs.map((result) => FbUtils.queryDocToDoc(result))
    }

    static queryToSingleDoc = (
        queryDocs: QuerySnapshot,
    ): FirebaseDoc | undefined => {
        if (queryDocs.empty) {
            return undefined
        }
        if (queryDocs.docs.length > 1) {
            throw new Error(
                `More than one result. ids: ${queryDocs.docs.map(
                    (result) => result.id,
                )}`,
            )
        }
        return FbUtils.queryDocToDoc(queryDocs.docs[0])
    }

    static queryDocToDoc = (queryDoc: DocumentSnapshot): FirebaseDoc => {
        const id = queryDoc.id
        const doc = queryDoc.data()
        return {
            id,
            ...doc,
        }
    }
}
