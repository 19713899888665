import {
    Button,
    CardContent,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import { Warning } from "@material-ui/icons"
import clsx from "clsx"
import * as React from "react"
import { portalTheme } from "../../config/PortalTheme"
import { useGenericStyles } from "../../generic/GenericStyles"
import { portalUserStore } from "../../portal-users/stores/PortalUserStore"
import { activityNameFromKey } from "../../shared/models/ActivityKey"
import { ActivityProgress } from "../../shared/models/ActivityProgress"
import { activityProgressStore } from "../stores/ActivityProgressStore"

enum ActivityStatus {
    NOT_STARTED = "Not Started",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed",
    BYPASSED = "Bypassed By Admin",
}

interface LessonCardProps {
    studentId: string
    lesson: string
    activities: (ActivityProgress | undefined)[]
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2, 0),
            position: "relative",
        },
        chip: {
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
        },
        spacedContent: {
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
        },
        centeredContent: {
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
        },
    }),
)

export const LessonCard: React.FC<LessonCardProps> = ({
    studentId,
    lesson,
    activities,
}) => {
    const { surface } = useGenericStyles()
    const { root, chip } = useStyles()

    let chipLabel: {
        title: ActivityStatus
        color: "primary" | "secondary" | "default" | undefined
    } = { title: ActivityStatus.NOT_STARTED, color: "default" }
    if (activities) {
        if (activities.every((item) => (item ? !!item.finish : false))) {
            chipLabel = {
                title: ActivityStatus.COMPLETED,
                color: "primary",
            }
        } else if (activities.some((item) => (item ? !!item.start : false))) {
            chipLabel = {
                title: ActivityStatus.IN_PROGRESS,
                color: "secondary",
            }
        }
    }

    return (
        <Card className={clsx(surface, root)}>
            <CardHeader
                title={lesson}
                titleTypographyProps={{ variant: "h5" }}
            />
            <Chip
                label={chipLabel.title}
                className={chip}
                color={chipLabel.color}
                size={"small"}
                style={{
                    color:
                        chipLabel.title === ActivityStatus.NOT_STARTED
                            ? "white"
                            : "",
                    backgroundColor:
                        chipLabel.title === ActivityStatus.NOT_STARTED
                            ? portalTheme.palette.grey[400]
                            : "",
                }}
            />
            {activities &&
                activities.map((activity, idx) => (
                    <Activity
                        studentId={studentId}
                        activity={activity}
                        key={activity ? activity.activity : idx}
                    />
                ))}
        </Card>
    )
}

const Activity: React.FC<{
    activity?: ActivityProgress
    studentId: string
}> = ({ activity, studentId }) => {
    const [bypassDialogOpen, setBypassOpen] = React.useState(false)
    const { bypassLatestActivity } = activityProgressStore
    const { portalUser } = portalUserStore

    const { spacedContent, centeredContent } = useStyles()
    if (activity == null) {
        return null
    }
    const notStarted = activity.finish == null && activity.start == null
    const inProgress = activity.finish == null && activity.start != null
    const completed = activity.finish != null && activity.start != null

    let activityProgress

    if (notStarted) {
        activityProgress = ActivityStatus.NOT_STARTED
    } else if (inProgress) {
        activityProgress = ActivityStatus.IN_PROGRESS
    } else if (activity.bypassedByAdmin) {
        activityProgress = `${ActivityStatus.BYPASSED} (${activity
            .finish!.toDate()
            .toLocaleDateString()})`
    } else if (completed) {
        activityProgress = `${ActivityStatus.COMPLETED} (${activity
            .finish!.toDate()
            .toLocaleDateString()})`
    }

    async function handleBypassActivityConfirmed() {
        setBypassOpen(false)
        try {
            await bypassLatestActivity(studentId)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <CardContent>
            <div className={spacedContent}>
                <Typography variant={"subtitle1"}>
                    {activityNameFromKey.get(activity.activity)}
                </Typography>
                <Typography
                    variant={"caption"}
                    color={notStarted ? "textSecondary" : "primary"}
                >
                    {activityProgress}
                </Typography>
            </div>
            <div className={centeredContent}>
                {portalUser.value?.admin && inProgress && (
                    <Button
                        color="primary"
                        endIcon={<Warning />}
                        onClick={() => setBypassOpen(true)}
                    >
                        Admin Bypass Activity
                    </Button>
                )}
            </div>
            <Divider />
            <Dialog
                open={bypassDialogOpen}
                onClose={() => setBypassOpen(false)}
            >
                <DialogTitle>
                    Bypass: {activityNameFromKey.get(activity.activity)}?
                </DialogTitle>
                <DialogContent>
                    Once bypassed, the student will not have to complete this
                    activity. Applicable usage restrictions still apply.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBypassOpen(false)}>Cancel</Button>
                    <Button
                        onClick={handleBypassActivityConfirmed}
                        color="primary"
                    >
                        Bypass
                    </Button>
                </DialogActions>
            </Dialog>
        </CardContent>
    )
}
