import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import "typeface-archivo-black"
import "typeface-roboto"

export const portalTheme = createMuiTheme({
    typography: {
        fontFamily: "roboto",
        h1: {
            fontFamily: "Archivo Black",
        },
        h2: {
            fontFamily: "Archivo Black",
        },
        h4: {
            fontFamily: "Archivo Black",
        },
        h5: {
            fontWeight: "bold",
        },
        h6: {
            fontWeight: "normal",
        },
        subtitle1: {
            lineHeight: 1.5,
            letterSpacing: "0.2px",
        },
        subtitle2: {
            fontFamily: "Archivo Black",
        },
        overline: {
            color: "#00000099",
            letterSpacing: 1.5,
            fontWeight: 500,
            fontSize: 12
        }
    },

    palette: {
        common: {black: "#000", white: "#fff"},
        background: {paper: "#fafafa", default: "#fff"},
        primary: {
            main: "#2d67ca",
        },
        secondary: {
            main: "#FCCF2E",
        },
        error: {
            main: "#b00020",
        }
    },
    overrides: {
        MuiPaper: {
            root: {
                border: "solid 1px #0000001e",
            }
        },
        MuiFab: {
            label: {
                fontWeight: "bold",
                lineHeight: "16px",
                letterSpacing: "1.3px",
                paddingLeft: 16,
                paddingRight: 16
            }
        },

        MuiTable: {
            root: {
                backgroundColor: "#fff"
            }
        },
        MuiFormControlLabel: {
            label: {
                color: "#00000099"
            }
        }
    },
    props: {
        MuiPaper: {
            elevation: 0,
        },
        MuiCard: {
            elevation: 0
        },
        MuiFab: {
            size: "small",
            variant: "extended"

        },
        MuiTextField: {
            variant: "outlined",
            InputLabelProps: {
                required: false
            }
        }
    }
})