import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { classStore } from "../../classes/stores/ClassStore"
import { Loader } from "../../generic/atoms/Loader"
import { log } from "../../config/Logging"
import { RoutePaths } from "../../routing/RoutePaths"
import {
    Lessons5thGrade,
    Lessons6thGrade,
} from "../../shared/models/ActivityKey"
import { ActivityProgress } from "../../shared/models/ActivityProgress"
import { ClassGrade } from "../../shared/models/Clazz"
import { EditStudent } from "../organisms/EditStudent"
import { LessonCard } from "../organisms/LessonCard"
import { activityProgressStore } from "../stores/ActivityProgressStore"
import { studentStore } from "../stores/StudentStore"
import { DeleteConfirmation } from "../../generic/molecules/DeleteConfimation"

const useStyles = makeStyles((theme) =>
    createStyles({
        studentPage: {
            margin: theme.spacing(4),
            width: "100%",
            maxWidth: 800,
        },
        top: {
            display: "flex",
            marginBottom: theme.spacing(4),
        },
        deleteButton: {
            margin: theme.spacing(1),
            color: theme.palette.getContrastText(theme.palette.error.main),
            backgroundColor: theme.palette.error.main,
            "&:hover": {
                backgroundColor: theme.palette.error.main,
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: theme.palette.error.main,
                },
            },
        },
        // This is required for the '&$disabled' selector to work
        disabled: {},
    }),
)

export const StudentPage = observer(() => {
    const { top, studentPage } = useStyles()
    const { classId, studentId } = useParams()
    const routerHistory = useHistory()
    const { studentName, deleteStudent } = studentStore
    const { studentActivityProgress } = activityProgressStore

    useEffect(() => {
        const unsub = studentStore.subscribeToStudent(studentId)
        const unsub2 =
            activityProgressStore.subscribeToStudentActivityProgress(studentId)
        const unsub3 = classStore.subscribeToClass(classId)
        return () => {
            unsub()
            unsub2()
            unsub3()
        }
    }, [classId, studentId])

    const classGrade = classStore.clazz.value?.classGrade
    const studentProgress = studentActivityProgress.value?.progress

    if (classGrade === undefined || studentProgress === undefined) {
        return <Loader />
    }

    const handleDeleteStudent = async () => {
        try {
            await deleteStudent()
            routerHistory.push(RoutePaths.viewClass(classId))
        } catch (error) {
            log.debug(`Failure to delete student ${error}`)
            return Promise.reject(error)
        }
    }

    return (
        <div className={studentPage}>
            <Typography className={top} variant={"h4"}>
                {studentName}
            </Typography>
            <Grid container={true} spacing={10}>
                <Grid item={true} sm={12} md={9}>
                    {mapProgressToCard(studentProgress, classGrade).map(
                        (progress) => (
                            <LessonCard
                                studentId={studentId}
                                lesson={progress.lesson}
                                activities={progress.activities}
                                key={progress.lesson}
                            />
                        ),
                    )}
                </Grid>
                <Grid item={true} sm={12} md={3}>
                    <EditStudent />
                    <DeleteConfirmation
                        entity={"Student"}
                        deletion={handleDeleteStudent}
                    />
                </Grid>
            </Grid>
        </div>
    )
})

const mapProgressToCard = (progress: ActivityProgress[], grade: ClassGrade) => {
    return (grade === ClassGrade.FIFTH ? Lessons5thGrade : Lessons6thGrade).map(
        (lesson) => {
            const activities: (ActivityProgress | undefined)[] =
                lesson.activities.map((activity) => {
                    const studentActivity = progress.find(
                        (prog) => prog.activity === activity,
                    )
                    return { ...studentActivity, activity }
                })
            return { lesson: `Lesson ${lesson.name}`, activities }
        },
    )
}
