import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core"
import Fab from "@material-ui/core/Fab"
import { computed, observable } from "mobx"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { portalTheme } from "../../config/PortalTheme"
import { Loader } from "../../generic/atoms/Loader"
import { schoolStore } from "../../schools/stores/SchoolStore"
import { ClassGrade, Clazz } from "../../shared/models/Clazz"
import { classStore } from "../stores/ClassStore"

class Store {
    @observable
    open = false

    @observable
    name = ""

    @observable
    grade = ClassGrade.FIFTH

    @observable
    saving = false

    @observable
    errorLoadingSchool = false

    constructor(private teacherId: string, private clazz?: Clazz) {
        if (clazz != null) {
            this.name = clazz.name ?? ""
            this.grade = clazz.classGrade
        }
    }

    makeOpen = () => (this.open = true)
    close = () => (this.open = false)

    updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.name = event.target.value
    }

    updateGrade = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.grade = event.target.value as ClassGrade
    }

    save = async () => {
        this.saving = true
        const name = this.name.trim()
        if (this.clazz != null) {
            await classStore.updateClass(this.clazz.id!, name)
        } else {
            await classStore.createClass(this.teacherId, name, this.grade)
        }
        this.saving = false
        this.open = false
    }

    @computed
    get valid(): boolean {
        return this.name.trim().length > 1
    }
}

export const SaveClassForm = observer(
    (props: { clazz?: Clazz; schoolId?: string; teacherId: string }) => {
        const { clazz, schoolId, teacherId } = props
        const { school } = schoolStore
        const [store] = useState(new Store(teacherId, clazz))
        const includesFifth = school.value?.options.includesFifth
        const includesSixth = school.value?.options.includesSixth

        useEffect(() => {
            if (schoolId) {
                return schoolStore.subscribeToSchool(schoolId)
            }
        }, [schoolId])

        const formatGrade = (grade: ClassGrade) => {
            switch (grade) {
                case ClassGrade.FIFTH:
                    return "Fifth Grade"
                case ClassGrade.SIXTH:
                    return "Sixth Grade"
            }
        }
        const gradeValue = () => {
            if (!includesFifth && includesSixth) {
                return formatGrade(ClassGrade.SIXTH)
            } else if (includesFifth && includesSixth) {
                return store.grade
            } else {
                return formatGrade(ClassGrade.FIFTH)
            }
        }
        const fifthGradeMenuItem = (
            <MenuItem value={ClassGrade.FIFTH}>
                {formatGrade(ClassGrade.FIFTH)}
            </MenuItem>
        )
        const sixthGradeMenuItem = (
            <MenuItem value={ClassGrade.SIXTH}>
                {formatGrade(ClassGrade.SIXTH)}
            </MenuItem>
        )
        const gradeMenu = (
            <div>
                <TextField
                    label={"Grade"}
                    fullWidth={true}
                    value={gradeValue()}
                    onChange={store.updateGrade}
                    select={includesFifth && includesSixth}
                    style={{ marginTop: portalTheme.spacing(2) }}
                >
                    {includesFifth ? fifthGradeMenuItem : null}
                    {includesSixth ? sixthGradeMenuItem : null}
                </TextField>
                {!(includesFifth && includesSixth) ? (
                    <Typography
                        variant={"caption"}
                        color={"textSecondary"}
                        style={{ margin: portalTheme.spacing(2) }}
                    >
                        {includesFifth !== includesSixth
                            ? `There are no other grades configured for your school.`
                            : `There are no grades configured for your school. Defaulting to fifth grade.`}
                    </Typography>
                ) : null}
            </div>
        )

        return (
            <div>
                {clazz == null ? (
                    <Fab
                        size={"medium"}
                        color={"primary"}
                        onClick={store.makeOpen}
                    >
                        Add Class
                    </Fab>
                ) : (
                    <Typography onClick={store.makeOpen}></Typography>
                )}
                <Dialog open={store.open} onClose={store.close}>
                    <DialogTitle>
                        {clazz == null ? "Add" : "Edit"} Class
                    </DialogTitle>
                    {school.loading ? (
                        store.errorLoadingSchool ? (
                            <Typography
                                variant={"subtitle1"}
                                color={"error"}
                                style={{ margin: portalTheme.spacing(2) }}
                            >
                                An error occured which may be resolved by
                                reloading this page. If the error persists,
                                contact an administrator.
                            </Typography>
                        ) : (
                            <Loader />
                        )
                    ) : (
                        <DialogContent>
                            <TextField
                                autoFocus
                                label={"Name"}
                                fullWidth={true}
                                value={store.name}
                                onChange={store.updateName}
                            />
                            {clazz == null ? gradeMenu : null}
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button onClick={store.close} disabled={store.saving}>
                            Cancel
                        </Button>
                        <Button
                            onClick={store.save}
                            color="primary"
                            disabled={store.saving || !store.valid}
                        >
                            Save
                            {store.saving && (
                                <CircularProgress
                                    size={portalTheme.spacing(2)}
                                />
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    },
)
