import { createStyles, Dialog } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CloseIcon from "@material-ui/icons/Close"
import { observer } from "mobx-react"
import React, { SetStateAction } from "react"
import { classStore } from "../../classes/stores/ClassStore"
import { studentJoinUrl } from "../../config/PortalUtils"
import { Loader } from "../../generic/atoms/Loader"

const useStyles = makeStyles(theme => createStyles({
    dialog: {
        position: "relative",
        textAlign: "center",
        "& .MuiPaper-root": {
            padding: theme.spacing(1, 4)
        },
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0,
    }
}))


interface InviteDialogProps {
    open: boolean
    setOpen: React.Dispatch<SetStateAction<boolean>>
}

export const InviteDialog: React.FC<InviteDialogProps> = observer(({open, setOpen}) => {
    const {dialog, closeButton} = useStyles()
    const {clazz} = classStore

    return (
        <Dialog
            onClose={() => setOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={open}
            className={dialog}
        >
            <DialogTitle id="simple-dialog-title">Invite Students</DialogTitle>
            <IconButton className={closeButton} aria-label="close" onClick={() => setOpen(false)}>
                <CloseIcon/>
            </IconButton>
            <DialogContent>
                <DialogContentText>Students can go to</DialogContentText>
                <Link href={studentJoinUrl}>app.clickcity.org/join-class</Link>
            </DialogContent>
            <DialogContent>
                <DialogContentText>And enter the class code</DialogContentText>
                {clazz.loading && <Loader/>}
                {clazz.value && <Link href={studentJoinUrl}>{clazz.value.code.toUpperCase()}</Link>}
                <DialogContentText>to join your class</DialogContentText>
            </DialogContent>

        </Dialog>
    )
})
