import { CssBaseline, MuiThemeProvider } from "@material-ui/core"
import React from "react"
import { portalTheme } from "./config/PortalTheme"
import { GlobalSnackmessage } from "./generic/stores/MessageStore"
import { CCPRouter } from "./routing/CCPRouter"
import "./App.css"

const App: React.FC = () => {
    return (
        <MuiThemeProvider theme={portalTheme}>
            <CssBaseline />
            <CCPRouter />
            <GlobalSnackmessage />
        </MuiThemeProvider>
    )
}

export default App
