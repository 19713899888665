import {
    createStyles,
    makeStyles,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    CircularProgress,
} from "@material-ui/core"
import { portalTheme } from "../../config/PortalTheme"
import Fab from "@material-ui/core/Fab"
import clsx from "clsx"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { RoutePaths } from "../../routing/RoutePaths"
import { classStore } from "../../classes/stores/ClassStore"
import { Loader } from "../../generic/atoms/Loader"
import { log } from "../../config/Logging"
import { useGenericStyles } from "../../generic/GenericStyles"
import { classGradeName } from "../../shared/models/Clazz"
import { InviteDialog } from "../organisms/InviteDialog"
import { StudentTable } from "../organisms/StudentTable"
import { activityProgressStore } from "../stores/ActivityProgressStore"
import { studentStore } from "../stores/StudentStore"
import { DeleteConfirmation } from "../../generic/molecules/DeleteConfimation"
import { portalUserStore } from "../../portal-users/stores/PortalUserStore"

const useStyles = makeStyles((theme) =>
    createStyles({
        studentListPage: {
            margin: theme.spacing(2),
            maxWidth: "90%",
        },
        top: {
            display: "flex",
            alignItems: "flex-end",
            marginBottom: theme.spacing(4),
        },
        table: {},
        archiveButton: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    }),
)

export const StudentListPage = observer(() => {
    const { grow, marginRight } = useGenericStyles()
    const { top, studentListPage, archiveButton } = useStyles()
    const [inviteOpen, setInviteOpen] = React.useState(false)
    const [editOpen, setEditOpen] = React.useState(false)
    const { classId } = useParams()
    const routerHistory = useHistory()
    const { deleteClass } = classStore
    const { portalUser } = portalUserStore

    interface ClassEditState {
        applyingChanges: boolean
        className: string
        archived: boolean
    }
    const [state, setState] = React.useState<ClassEditState>({
        applyingChanges: false,
        className: "",
        archived: false,
    })

    useEffect(() => {
        const unsub =
            activityProgressStore.subscribeToActivityProgresses(classId)
        const unsub2 = studentStore.subscribeToStudents(classId)
        const unsub3 = classStore.subscribeToClass(classId)
        return () => {
            unsub()
            unsub2()
            unsub3()
        }
    }, [classId])

    const className = classStore.clazz.value?.name
    const classGrade = classStore.clazz.value?.classGrade
    if (classStore.clazz.value?.archived !== undefined) {
        state.archived = classStore.clazz.value?.archived
    }

    if (className == null || classGrade == null) {
        return <Loader />
    }

    const openEditDialog = () => {
        setState({
            ...state,
            className: className,
        })
        setEditOpen(true)
    }
    const closeEditDialog = () => setEditOpen(false)

    const updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            className: event.target.value,
        })
    }

    const changeName = async () => {
        setState({
            ...state,
            applyingChanges: true,
        })
        try {
            await classStore.updateClass(classId, state.className)
            setState({
                ...state,
                applyingChanges: false,
            })
            closeEditDialog()
        } catch (error) {
            setState({
                ...state,
                applyingChanges: false,
            })
        }
    }

    const archiveClassButton = async () => {
        try {
            await classStore.updateClassArchive(classId, !state.archived)
            setState({
                ...state,
                archived: !state.archived,
            })
        } catch (error) {}
    }

    const handleDeleteClass = async () => {
        try {
            const teacherId = classStore.clazz.value?.teacherId
            await deleteClass()
            routerHistory.push(RoutePaths.classesForTeacher(teacherId))
        } catch (error) {
            log.debug(`Failure to delete class ${error}`)
            return Promise.reject(error)
        }
    }

    return (
        <div className={clsx(studentListPage, grow)}>
            <div className={top}>
                {state.archived && (
                    <Typography
                        variant={"h4"}
                        className={marginRight}
                        color="primary"
                    >
                        Archived
                    </Typography>
                )}
                <Typography variant={"h4"} className={marginRight}>
                    {className}
                </Typography>
                <Typography variant={"h6"} className={grow}>
                    {classGradeName(classGrade)}
                </Typography>
                <Button onClick={openEditDialog}>Edit</Button>
                <Fab
                    size={"medium"}
                    color={"primary"}
                    onClick={() => setInviteOpen(true)}
                >
                    Class Code
                </Fab>
                <InviteDialog open={inviteOpen} setOpen={setInviteOpen} />
            </div>
            {portalUser.value?.admin && (
                <div className={top}>
                    <DeleteConfirmation
                        entity={"Class"}
                        deletion={handleDeleteClass}
                    />
                </div>
            )}
            <StudentTable />
            <Button
                className={archiveButton}
                onClick={archiveClassButton}
                variant="contained"
            >
                {state.archived ? "Unarchive" : "Archive"}
            </Button>

            <div style={{ textAlign: "center", color: "#5A626C" }}>
                <Typography variant="body2">
                    When students join the class, you can view their progress,
                    modify their profiles (including screenname, ID, birthday),
                    or change their usage settings
                </Typography>
            </div>

            <Dialog open={editOpen} onClose={closeEditDialog}>
                <DialogTitle>Edit Class</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        label={"Name"}
                        fullWidth={true}
                        value={state.className}
                        onChange={updateName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeEditDialog}
                        disabled={state.applyingChanges}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={changeName}
                        color="primary"
                        disabled={state.applyingChanges}
                    >
                        Save
                        {state.applyingChanges && (
                            <CircularProgress size={portalTheme.spacing(2)} />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
})
