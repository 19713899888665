import { Button } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { authStore } from "../../auth/stores/AuthStore"
import { fbAuth } from "../../config/FirebaseConfig"
import { log } from "../../config/Logging"
import { portalUserStore } from "../../portal-users/stores/PortalUserStore"
import { RoutePaths } from "../../routing/RoutePaths"
import { LinkButton } from "../atoms/LinkButton"
import { useGenericStyles } from "../GenericStyles"
import { TeacherProfile } from "./TeacherProfile"
import ClickCityLogo from "../../generic/images/click-city-logo.png"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            flexDirection: "row",
        },
        title: {
            flexGrow: 1,
            display: "flex",
            textDecoration: "none",
            color: "inherit",
        },
        logo: {
            height: theme.spacing(4),
            marginRight: theme.spacing(2),
        },
    }),
)

export const TeacherBar = observer(() => {
    const { logo, title, root } = useStyles()
    const { grow } = useGenericStyles()
    const [profileOpen, setProfileOpen] = React.useState(false)
    const { portalUser, clearPortalUser } = portalUserStore
    const user = portalUser.value
    const { authUser } = authStore

    useEffect(() => {
        if (
            user != null &&
            (!user.firstName || !user.lastName || user.preferredTitle === "")
        ) {
            log.debug("Set profile open for Teacher bar")
            setProfileOpen(true)
        }
    }, [user])

    const logout = async () => {
        try {
            await fbAuth().signOut()
            clearPortalUser()
        } catch (error) {
            log.debug(`Failed to sign out: ${error}`)
        }
    }

    let teacherClassesLink
    let isTeacher = false
    if (portalUser.value != null) {
        if (portalUser.value.admin) {
            teacherClassesLink = RoutePaths.schools
        } else {
            isTeacher = true
            teacherClassesLink = RoutePaths.classesForTeacher(
                portalUser.value.id,
            )
        }
    }

    const header = teacherClassesLink ? (
        <>
            <Link to={teacherClassesLink} className={title}>
                <img
                    src={ClickCityLogo}
                    className={logo}
                    alt={"Click City: Tobacco Logo"}
                />
                <Typography variant="h6">
                    Click City: Tobacco{" "}
                    {user != null && user.admin ? "Admin" : "Teacher"} Portal
                </Typography>
            </Link>
            {authUser && user && (
                <>
                    <LinkButton
                        variant="text"
                        color={"primary"}
                        to={RoutePaths.resources}
                    >
                        Teacher Resources
                    </LinkButton>
                    {isTeacher && (
                        <LinkButton
                            variant={"text"}
                            color={"primary"}
                            to={teacherClassesLink}
                        >
                            Classes
                        </LinkButton>
                    )}
                    <Button
                        variant={"text"}
                        color={"primary"}
                        onClick={() => setProfileOpen(true)}
                    >
                        Profile
                    </Button>
                    <Button color="primary" onClick={() => logout()}>
                        Logout
                    </Button>
                    <TeacherProfile
                        profileOpen={profileOpen}
                        setProfileOpen={setProfileOpen}
                        portalUser={user}
                        allowCancel={true}
                    />
                </>
            )}
        </>
    ) : (
        <>
            <img
                src={ClickCityLogo}
                className={logo}
                alt={"Click City: Tobacco Logo"}
            />
            <Typography variant="h6">
                Click City: Tobacco Teacher and Admin Portal
            </Typography>
        </>
    )

    return (
        <AppBar
            color={"default"}
            position={"static"}
            elevation={0}
            className={clsx(grow, root)}
        >
            {header}
        </AppBar>
    )
})
