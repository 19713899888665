import { createStyles, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { log } from "../../config/Logging"
import { useGenericStyles } from "../../generic/GenericStyles"
import { DeleteConfirmation } from "../../generic/molecules/DeleteConfimation"
import { portalUserStore } from "../../portal-users/stores/PortalUserStore"
import { teacherStore } from "../../portal-users/stores/TeacherStore"
import { RoutePaths } from "../../routing/RoutePaths"
import { ClassList } from "../organisms/ClassList"
import { SaveClassForm } from "../organisms/SaveClassForm"
import { classStore } from "../stores/ClassStore"

const useStyles = makeStyles((theme) =>
    createStyles({
        classListPage: {
            margin: theme.spacing(4),
            maxWidth: 800,
        },
        top: {
            display: "flex",
            marginBottom: theme.spacing(4),
        },
        table: {},
    }),
)

export const ClassListPage = observer(() => {
    const { grow } = useGenericStyles()
    const { top, classListPage } = useStyles()

    const { teacherId } = useParams()
    const { schoolId, deleteTeacher } = teacherStore
    const { activeClasses, archivedClasses } = classStore
    const { portalUser } = portalUserStore
    const routerHistory = useHistory()

    log.debug(`Teacher id in class list page: ${teacherId}`)

    useEffect(() => {
        const unsubTeacher = teacherStore.subscribeToTeacher(teacherId)
        const unsubClasses = classStore.subscribeToClassesForTeacher(teacherId)
        return () => {
            unsubTeacher()
            unsubClasses()
        }
    }, [teacherId])

    const handleDeleteTeacher = async () => {
        try {
            await deleteTeacher()
            routerHistory.push(RoutePaths.schoolDetails(schoolId))
        } catch (error) {
            log.debug(`Failure to delete class ${error}`)
            return Promise.reject(error)
        }
    }

    return (
        <div className={clsx(classListPage, grow)}>
            <div className={top}>
                {teacherStore.fullName && (
                    <Typography variant={"h4"}>
                        {teacherStore.fullName}'s Classes
                    </Typography>
                )}
                <div className={grow} />
                {schoolId ? (
                    <SaveClassForm teacherId={teacherId} schoolId={schoolId} />
                ) : null}
            </div>
            {portalUser.value?.admin && (
                <div className={top}>
                    <DeleteConfirmation
                        entity={"Teacher"}
                        deletion={handleDeleteTeacher}
                    />
                </div>
            )}
            <ClassList
                activeClasses={activeClasses.value}
                archivedClasses={archivedClasses.value}
            />
            <div style={{ textAlign: "center", color: "#5A626C" }}>
                <Typography variant="body2">
                    To create a new class, select ‘Add Class’
                </Typography>
            </div>
        </div>
    )
})
