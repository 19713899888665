import {
    Typography,
    createStyles,
    makeStyles,
    Button,
    Modal,
    Fade,
    Popover,
} from "@material-ui/core"
import { DeleteForever, ReportProblemOutlined } from "@material-ui/icons"
import React from "react"
import { log } from "../../config/Logging"

const useStyles = makeStyles((theme) =>
    createStyles({
        deleteButton: {
            color: theme.palette.getContrastText(theme.palette.error.main),
            backgroundColor: theme.palette.error.main,
            "&:hover": {
                backgroundColor: theme.palette.error.main,
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: theme.palette.error.main,
                },
            },
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: "none",
        },
        icon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.error.main,
        },
        cancelButton: {
            margin: theme.spacing(1),
        },
        typography: {
            padding: theme.spacing(2),
        },
    }),
)

export const DeleteConfirmation = (props: {
    entity: string
    deletion: () => Promise<void>
}) => {
    const {
        deleteButton,
        modal,
        paper,
        cancelButton,
        icon,
        typography,
    } = useStyles()
    const [open, setOpen] = React.useState(false)
    const [errorOpen, setErrorOpen] = React.useState(false)

    const deleteEntity = async () => {
        try {
            await props.deletion()
        } catch (error) {
            log.debug(`Failure to delete class ${error}`)
            setErrorOpen(true)
            setOpen(false)
        }
    }

    const openConfirmationModal = () => setOpen(true)

    const closeConfirmationModal = () => setOpen(false)

    const closePopout = () => setErrorOpen(false)

    return (
        <div>
            <Button
                variant="contained"
                className={deleteButton}
                startIcon={<DeleteForever />}
                onClick={openConfirmationModal}
            >
                Delete {props.entity}
            </Button>
            <Modal
                className={modal}
                open={open}
                onClose={closeConfirmationModal}
                aria-labelledby="Confirmation to delete class"
            >
                {
                    <Fade in={open}>
                        <div className={paper}>
                            <div className={icon}>
                                <ReportProblemOutlined
                                    style={{ fontSize: 80 }}
                                />
                            </div>
                            <h2 className={modal}>
                                Delete this {props.entity}?
                            </h2>
                            <p className={modal}>
                                This action cannot be undone
                            </p>
                            <Button
                                variant="contained"
                                className={cancelButton}
                                onClick={closeConfirmationModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                className={deleteButton}
                                onClick={deleteEntity}
                            >
                                Delete {props.entity}
                            </Button>
                        </div>
                    </Fade>
                }
            </Modal>
            <Popover
                open={errorOpen}
                onClose={closePopout}
                anchorReference={"anchorPosition"}
                anchorPosition={{
                    left: 0,
                    top: 0,
                }}
            >
                <Typography className={typography} color="error">
                    Error: {props.entity} could not be deleted
                </Typography>
            </Popover>
        </div>
    )
}
