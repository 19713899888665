import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
    Chip,
    makeStyles,
    Button,
    createStyles,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { capitalize } from "lodash"
import React from "react"
import { Loader } from "../../generic/atoms/Loader"
import { RoutePaths } from "../../routing/RoutePaths"
import { Clazz } from "../../shared/models/Clazz"
import { SaveClassForm } from "./SaveClassForm"

const useStyles = makeStyles((theme) =>
    createStyles({
        chip: {
            padding: theme.spacing(2),
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
    }),
)

export const ClassList = (props: {
    activeClasses?: Clazz[]
    archivedClasses?: Clazz[]
}) => {
    const { chip, marginBottom } = useStyles()
    const { activeClasses, archivedClasses } = props

    interface ClassCardState {
        showArchived: boolean
    }
    const [state, setState] = React.useState<ClassCardState>({
        showArchived: false,
    })

    const toggleArchivedClasses = () => {
        setState({ ...state, showArchived: !state.showArchived })
    }

    if (activeClasses == null || archivedClasses == null) {
        return <Loader />
    }

    const activeClassCards = activeClasses.map((clazz, idx) => (
        <Card key={clazz.id} className={marginBottom}>
            <CardActionArea
                component={RouterLink}
                to={RoutePaths.viewClass(clazz.id)}
            >
                <CardHeader
                    title={clazz.name == null ? `Class ${idx + 1}` : clazz.name}
                    action={
                        <Chip
                            className={chip}
                            color="primary"
                            label="Active"
                            variant="outlined"
                        />
                    }
                />
                <CardContent>
                    <Typography variant="h6">
                        {capitalize(clazz.classGrade.toLowerCase())} Grade
                    </Typography>
                </CardContent>
                <CardActions>
                    <SaveClassForm clazz={clazz} teacherId={clazz.teacherId} />
                </CardActions>
                <div style={{ textAlign: "center", color: "#5A626C" }}>
                    <Typography variant="body2">Select to open</Typography>
                </div>
            </CardActionArea>
        </Card>
    ))

    const archivedClassCards = archivedClasses.map((clazz, idx) => (
        <Card key={clazz.id} className={marginBottom}>
            <CardActionArea
                component={RouterLink}
                to={RoutePaths.viewClass(clazz.id)}
            >
                <CardHeader
                    title={clazz.name == null ? `Class ${idx + 1}` : clazz.name}
                    action={
                        <Chip
                            className={chip}
                            label="Archived"
                            variant="outlined"
                        />
                    }
                />
                <CardContent>
                    <Typography variant="h6">
                        {capitalize(clazz.classGrade.toLowerCase())} Grade
                    </Typography>
                </CardContent>
                <CardActions>
                    <SaveClassForm clazz={clazz} teacherId={clazz.teacherId} />
                </CardActions>
                <div style={{ textAlign: "center", color: "#5A626C" }}>
                    <Typography variant="body2">Select to open</Typography>
                </div>
            </CardActionArea>
        </Card>
    ))

    return (
        <div>
            {activeClasses.length === 0 && (
                <Typography className={marginBottom} variant={"h6"}>
                    You don't have any active classes. Add one to get started!
                </Typography>
            )}
            {activeClassCards}
            <Button
                variant="contained"
                className={marginBottom}
                onClick={toggleArchivedClasses}
                disabled={archivedClasses.length === 0}
            >
                {state.showArchived
                    ? "Hide Archived Classes"
                    : "Show Archived Classes"}
            </Button>
            {state.showArchived && archivedClassCards}
        </div>
    )
}
