import { FBTimestamp, FirebaseDoc } from "../../config/FirebaseConfig"
import { ActivityKey } from "./ActivityKey"

export interface OverridesUpdate {
    studentId: string
    overrides: Overrides
}

export enum OverrideKeys {
    PER_DAY = "PER_DAY",
    CONSECUTIVE_DAYS = "CONSECUTIVE_DAYS",
    PER_WEEK = "PER_WEEK",
}

export interface Overrides {
    [OverrideKeys.PER_DAY]: boolean
    [OverrideKeys.CONSECUTIVE_DAYS]: boolean
    [OverrideKeys.PER_WEEK]: boolean
}

export interface AllActivityProgress extends FirebaseDoc {
    classId: string
    progress: ActivityProgress[]
    overrides: Overrides
}

export interface ActivityProgress {
    activity: ActivityKey
    bypassedByAdmin?: boolean
    start?: FBTimestamp
    finish?: FBTimestamp
}
