export class RoutePaths {
    static readonly home = "/"
    static readonly login = "/login"
    static readonly classes = "/classes"
    static readonly resources = "/resources"
    static readonly student = "/students"
    static readonly schools = "/schools"
    static readonly teachers = "/teachers"
    static readonly createSchool = `${RoutePaths.schools}/create`

    static viewClass = (classId?: string) =>
        `${RoutePaths.classes}/${classId == null ? ":classId" : classId}`
    static classesForTeacher = (teacherId?: string) =>
        `${RoutePaths.teachers}/${
            teacherId == null ? ":teacherId" : teacherId
        }/classes`
    static studentPage = (classId?: string, studentId?: string) =>
        `${RoutePaths.viewClass(classId)}${RoutePaths.student}/${
            studentId == null ? ":studentId" : studentId
        }`
    static inviteTeacher = (schoolId?: string) =>
        `${RoutePaths.schools}/${
            schoolId == null ? ":schoolId" : schoolId
        }/invite-teacher`
    static editSchool = (schoolId?: string) =>
        `${RoutePaths.schools}/${
            schoolId == null ? ":schoolId" : schoolId
        }/edit`
    static schoolDetails = (schoolId?: string) =>
        `${RoutePaths.schools}/${schoolId == null ? ":schoolId" : schoolId}`
}
