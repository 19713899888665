import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core"
import { OpenInNewOutlined } from "@material-ui/icons"
import React from "react"
import { LinkButton } from "../../generic/atoms/LinkButton"

import Gr5Newsletter1 from "../assets/gr5-newsletter-1.pdf"
import Gr5Newsletter2 from "../assets/gr5-newsletter-2.pdf"
import Gr5Newsletter3 from "../assets/gr5-newsletter-3.pdf"
import Gr6Newsletter1 from "../assets/gr6-newsletter-1.pdf"
import Gr6Newsletter2 from "../assets/gr6-newsletter-2.pdf"
import Gr5Quiz from "../assets/gr5-quiz.pdf"
import Gr6Quiz from "../assets/gr6-quiz.pdf"
import StudentLoginInstructions from "../assets/student-login-instructions.pdf"
import TeachersGuide from "../assets/teachers-guide.pdf"

const ResourcePage = () => {
    const { resourceContainer } = makeStyles((theme: Theme) =>
        createStyles({
            resourceContainer: {
                display: "flex",
                flexDirection: "column",
                padding: theme.spacing(4),
            },
        }),
    )()

    const resourceToButton = (resource: { pdf: any; label: string }) => (
        <LinkButton
            key={resource.label}
            to={resource.pdf}
            endIcon={<OpenInNewOutlined />}
            target="_blank"
            variant="text"
            color="primary"
            size="large"
        >
            {resource.label}
        </LinkButton>
    )

    const fifthGradeResources = [
        {
            pdf: StudentLoginInstructions,
            label: "Student Login Instructions Handout",
        },
        {
            pdf: Gr5Newsletter1,
            label: "Newsletter #1",
        },
        {
            pdf: Gr5Newsletter2,
            label: "Newsletter #2",
        },
        {
            pdf: Gr5Newsletter3,
            label: "Newsletter #3",
        },
        {
            pdf: Gr5Quiz,
            label: "Quiz",
        },
    ].map(resourceToButton)

    const sixthGradeResources = [
        {
            pdf: StudentLoginInstructions,
            label: "Student Login Instructions Handout",
        },
        {
            pdf: Gr6Newsletter1,
            label: "Newsletter #1",
        },
        {
            pdf: Gr6Newsletter2,
            label: "Newsletter #2",
        },
        {
            pdf: Gr6Quiz,
            label: "Quiz",
        },
    ].map(resourceToButton)

    return (
        <div>
            <Typography variant={"h4"}>Teacher Resources</Typography>

            <div className={resourceContainer}>
                <Typography variant={"h5"}>Teacher's Guide</Typography>
                {resourceToButton({
                    pdf: TeachersGuide,
                    label: "Teacher's Guide",
                })}
            </div>

            <div className={resourceContainer}>
                <Typography variant={"h5"}>5th Grade (Year 1)</Typography>
                {fifthGradeResources}
            </div>

            <div className={resourceContainer}>
                <Typography variant={"h5"}>6th Grade (Year 2)</Typography>
                {sixthGradeResources}
            </div>
        </div>
    )
}

export default ResourcePage
