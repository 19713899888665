import { Button, createStyles, Divider, Input, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import { observer } from "mobx-react"
import React, { FC, useEffect, useState } from "react"
import MaskedInput from "react-text-mask"
import { log } from "../../config/Logging"
import { portalTheme } from "../../config/PortalTheme"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
            marginLeft: -10,
            padding: 0,
            textTransform: "capitalize"
        },
        divider: {
            margin: theme.spacing(1, 0),
        }
    }),
)

type StringOrRegex = string | RegExp

export const SaveableTextField: FC<{
    title: string,
    value: string,
    update: (value: string) => void,
    save: () => Promise<void | string>,
    valid?: (value: string) => boolean,
    mask?: StringOrRegex[],
    toMasked?: (value: string) => string
}> =
    observer(({title, value, update, save, valid, mask, toMasked}) => {
        const {button, divider} = useStyles()
        const [editMode, setEditMode] = useState(false)
        const [original, setOriginal] = useState(mask == null || toMasked == null ? value : toMasked(value))

        const [error, setError] = useState("")
        const [loading, setLoading] = useState<boolean>(false)

        const saveAndClose = async () => {
            setLoading(true)
            const err = await save()
            if (err && original !== value) {
                setError(err)
                setLoading(false)
            } else {
                setEditMode(false)
                setLoading(false)
                setError("")
            }
        }
        const cancelAndClose = () => {
            setEditMode(false)
            update(original)
        }

        useEffect(() => {
            if (!editMode) {
                log.debug("Set original for non-edit mode.")
                setOriginal(mask == null || toMasked == null ? value : toMasked(value))
            }
        }, [editMode, value, mask, toMasked])

        return (
            <div>
                <Typography variant={"overline"}>{title}</Typography>
                {editMode ? (
                    <div>
                        {mask == null || toMasked == null ? (
                            <TextField
                                value={value}
                                onChange={event => update(event.target.value)}
                                autoFocus={true}
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        saveAndClose()
                                    }
                                }}
                                helperText={error ? error : ""}
                            />
                        ) : (
                            <Input
                                value={toMasked(value)}
                                onChange={event => {
                                    log.debug(`Got value from text mask: ${event.target.value}`)
                                    update(event.target.value)
                                }}
                                inputComponent={
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    TextMaskCustom as any
                                }
                                inputProps={{mask}}
                                autoFocus={true}
                                onKeyPress={event => {
                                    const withValid = valid ? valid(value) : true
                                    if (event.key === "Enter" && withValid) {
                                        saveAndClose()
                                    }
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <Typography variant={"body1"}>{toMasked == null ? value : toMasked(value)}</Typography>
                )}
                <Button
                    className={button}
                    color={"primary"}
                    size={"small"}
                    onClick={() => {
                        if (editMode) {
                            saveAndClose()
                        } else {
                            setEditMode(true)
                        }
                    }}
                    disabled={(valid != null && !valid(value)) || value.length === 0}
                >
                    {loading ?
                        <CircularProgress size={portalTheme.spacing(4)}/> :
                        <Typography variant={"caption"}>{editMode ? "Save" : "Change"}</Typography>
                    }
                </Button>
                {editMode && (
                    <Button
                        className={button}
                        size={"small"}
                        onClick={cancelAndClose}
                    >
                        <Typography variant={"caption"}>Cancel</Typography>
                    </Button>
                )}
                <Divider className={divider}/>
            </div>
        )
    })

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void
    mask: StringOrRegex[]
}

function TextMaskCustom(props: TextMaskCustomProps) {
    const {inputRef, ...other} = props

    return (
        <MaskedInput
            {...other}
            ref={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (ref: any) => {
                    inputRef(ref ? ref.inputElement : null)
                }
            }
            showMask={true}
        />
    )
}
