import {
    Button,
    createStyles,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { portalTheme } from "../../config/PortalTheme"
import { LinkButton } from "../../generic/atoms/LinkButton"
import { Loader } from "../../generic/atoms/Loader"
import { useGenericStyles } from "../../generic/GenericStyles"
import { RoutePaths } from "../../routing/RoutePaths"
import { schoolStore } from "../../schools/stores/SchoolStore"
import { userInviteStore } from "../stores/UserInviteStore"

const useStyles = makeStyles((theme) =>
    createStyles({
        form: {
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            marginBottom: theme.spacing(2),
        },
        buttons: {
            marginTop: theme.spacing(2),
            display: "flex",
        },
    }),
)

interface InviteTeacherState {
    email: string
    name: string
    emailError?: boolean
}

export const InviteTeacherPage = observer(() => {
    const routerHistory = useHistory()
    const { marginTop, marginRight } = useGenericStyles()
    const styles = useStyles()
    const { schoolId } = useParams()
    const { school } = schoolStore
    const [inviteState, setInviteState] = useState<InviteTeacherState>({
        email: "",
        name: "",
    })

    useEffect(() => {
        return schoolStore.subscribeToSchool(schoolId)
    }, [schoolId])

    if (school.loading) {
        return <Loader />
    }

    const { name, email, emailError } = inviteState

    const checkForValidityAndSave = async () => {
        if (email.trim().length < 5 || !email.includes("@")) {
            setInviteState({ ...inviteState, email: "", emailError: true })
        } else {
            await userInviteStore.saveInvite({
                email: inviteState.email,
                name: inviteState.name,
                schoolId,
                role: "TEACHER",
            })
            routerHistory.push(".")
        }
    }

    return (
        <div style={{ maxWidth: 400 }}>
            <Grid>
                <Typography variant={"h5"}>Invite Teacher</Typography>
                <TextField
                    className={marginTop}
                    label={"name"}
                    value={name}
                    fullWidth={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInviteState({
                            ...inviteState,
                            name: event.target.value,
                        })
                    }}
                />
                <TextField
                    className={marginTop}
                    label={"email"}
                    value={email}
                    fullWidth={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInviteState({
                            ...inviteState,
                            email: event.target.value,
                            emailError: false,
                        })
                    }}
                    error={emailError}
                />
                <Typography
                    color={"textSecondary"}
                    style={{ marginTop: portalTheme.spacing(2) }}
                >
                    After saving, an invite will be in the system for this
                    teacher. They can use this invite to create a Click City:
                    Tobacco teacher account, where they can invite their
                    students and track their progress.
                </Typography>
                <div className={styles.buttons}>
                    <Button
                        className={marginRight}
                        variant={"contained"}
                        color={"primary"}
                        onClick={checkForValidityAndSave}
                    >
                        Save Invite
                    </Button>
                    <LinkButton
                        variant={"outlined"}
                        color={"primary"}
                        to={RoutePaths.schoolDetails(schoolId)}
                    >
                        Cancel
                    </LinkButton>
                </div>
            </Grid>
        </div>
    )
})
