import {
    Button,
    createStyles,
    makeStyles,
    MenuItem,
    Paper,
    TextField,
    Typography,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    FormHelperText,
} from "@material-ui/core"
import { observable } from "mobx"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { Redirect, useParams, useHistory } from "react-router-dom"
import { log } from "../../config/Logging"
import { portalTheme } from "../../config/PortalTheme"
import { enumValues } from "../../config/PortalUtils"
import { LinkButton } from "../../generic/atoms/LinkButton"
import { Loader } from "../../generic/atoms/Loader"
import { DeleteConfirmation } from "../../generic/molecules/DeleteConfimation"
import { RoutePaths } from "../../routing/RoutePaths"
import { USState } from "../../shared/USState"
import { schoolStore } from "../stores/SchoolStore"

const useStyles = makeStyles((theme) =>
    createStyles({
        formColumn: {
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
        },
        formRow: {
            display: "flex",
        },
        buttons: {
            marginTop: theme.spacing(1),
            display: "flex",
        },
        deleteButton: {
            marginLeft: theme.spacing(9),
        },
    }),
)

class SchoolFormErrors {
    @observable
    nameError?: boolean
    @observable
    cityError?: boolean
    @observable
    districtError?: boolean
    @observable
    gradeChoiceError?: boolean
}

export const EditSchoolForm = observer(() => {
    const styles = useStyles()
    const { schoolId } = useParams()
    const routerHistory = useHistory()

    const [store] = useState<SchoolFormErrors>(new SchoolFormErrors())

    useEffect(() => {
        let cleanup: (() => void) | undefined
        if (schoolId) {
            log.debug(`School id is ${schoolId} so subscribe`)
            cleanup = schoolStore.subscribeToSchool(schoolId)
        } else {
            log.debug("No school id, so make new one")
            schoolStore.createNewSchool = {
                name: "",
                state: USState.OR,
                district: "",
                city: "",
                options: {
                    includesFifth: false,
                    includesSixth: false,
                },
            }
        }
        return () => {
            if (cleanup) {
                cleanup()
            }
            schoolStore.schoolSaved = false
            schoolStore.createNewSchool = undefined
        }
    }, [schoolId])

    if (schoolStore.createNewSchool == null && schoolStore.school.loading) {
        return <Loader />
    }

    if (schoolStore.schoolSaved) {
        return <Redirect to={RoutePaths.schools} />
    }

    const school = schoolStore.createNewSchool ?? schoolStore.school.value!

    const checkForValidityAndSave = () => {
        if (school.name.trim().length < 1) {
            store.nameError = true
        } else if (school.district.trim().length < 1) {
            store.districtError = true
        } else if (school.city.trim().length < 1) {
            store.cityError = true
        } else if (
            !(school.options.includesFifth || school.options.includesSixth)
        ) {
            store.gradeChoiceError = true
        } else {
            if (schoolStore.createNewSchool) {
                schoolStore.saveNewSchool()
            } else {
                schoolStore.updateSchool()
            }
        }
    }
    const handleDeleteSchool = async () => {
        try {
            await schoolStore.deleteSchool()
            routerHistory.push(RoutePaths.schools)
        } catch (error) {
            log.debug(`Failure to delete school ${error}`)
            return Promise.reject(error)
        }
    }
    const schoolFields = (
        <div className={styles.formColumn}>
            <TextField
                label={"School Name"}
                value={school.name ? school.name : " "}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    store.nameError = false
                    school.name = event.target.value
                }}
                error={store.nameError}
            />
            <TextField
                style={{ marginTop: portalTheme.spacing(2) }}
                label={"District"}
                value={school.district ? school.district : " "}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    store.districtError = false
                    school.district = event.target.value
                }}
                error={store.districtError}
            />
            <TextField
                style={{ marginTop: portalTheme.spacing(2) }}
                label={"City"}
                value={school.city ? school.city : " "}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    store.cityError = false
                    school.city = event.target.value
                }}
                error={store.cityError}
            />
            <TextField
                style={{ marginTop: portalTheme.spacing(2) }}
                select={true}
                label={"State"}
                value={school.state ? school.state : ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    (school.state = event.target.value as USState)
                }
            >
                {enumValues(USState).map((state) => (
                    <MenuItem key={state} value={state}>
                        {state}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    )

    const schoolOptions = (
        <div className={styles.formColumn}>
            <FormControl error={store.gradeChoiceError}>
                <FormLabel>Included Curricula</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={
                                    school.options.includesFifth
                                        ? school.options.includesFifth
                                        : false
                                }
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    store.gradeChoiceError = false
                                    school.options.includesFifth =
                                        event.target.checked
                                }}
                            />
                        }
                        label="5th Grade"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={
                                    school.options.includesSixth
                                        ? school.options.includesSixth
                                        : false
                                }
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    store.gradeChoiceError = false
                                    school.options.includesSixth =
                                        event.target.checked
                                }}
                            />
                        }
                        label="6th Grade"
                    />
                </FormGroup>
                {store.gradeChoiceError ? (
                    <FormHelperText>Select at least one</FormHelperText>
                ) : null}
            </FormControl>
        </div>
    )

    return (
        <Paper className={styles.formColumn}>
            <Typography variant={"h4"}>
                {schoolStore.createNewSchool == null
                    ? "Edit School"
                    : "Create School"}
            </Typography>
            <div className={styles.formRow}>
                {schoolFields}
                {schoolOptions}
            </div>
            <div className={styles.buttons}>
                <Button
                    style={{ marginRight: portalTheme.spacing(2) }}
                    variant={"contained"}
                    color={"primary"}
                    onClick={checkForValidityAndSave}
                >
                    Save
                </Button>
                <LinkButton
                    variant={"outlined"}
                    color={"primary"}
                    to={RoutePaths.schools}
                >
                    Cancel
                </LinkButton>
                <div className={styles.deleteButton}>
                    {!schoolStore.createNewSchool && (
                        <DeleteConfirmation
                            entity={"School"}
                            deletion={handleDeleteSchool}
                        />
                    )}
                </div>
            </div>
        </Paper>
    )
})
