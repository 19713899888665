import { createStyles, Dialog, Theme } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import makeStyles from "@material-ui/core/styles/makeStyles"
import TextField from "@material-ui/core/TextField"
import { observer } from "mobx-react"
import React, { SetStateAction, useState } from "react"
import { log } from "../../config/Logging"
import { portalTheme } from "../../config/PortalTheme"
import { PortalUser } from "../../portal-users/models/PortalUser"
import { portalUserStore } from "../../portal-users/stores/PortalUserStore"
import { useForm } from "../hooks/UseForm"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            position: "relative",
            textAlign: "center",
            "& .MuiPaper-root": {
                padding: theme.spacing(1, 4),
            },
        },
        form: {
            "& > *": {
                marginBottom: theme.spacing(2.5),
            },
        },
    }),
)

interface TeacherProfileProps {
    allowCancel?: boolean
    profileOpen: boolean
    setProfileOpen: React.Dispatch<SetStateAction<boolean>>
    portalUser: PortalUser
}

export const TeacherProfile: React.FC<TeacherProfileProps> = observer(
    ({ allowCancel, profileOpen, setProfileOpen, portalUser }) => {
        const [saving, setSaving] = useState<boolean>(false)

        const { dialog, form } = useStyles()

        const { updatePortalUserProfile } = portalUserStore

        const saveTeacher = async () => {
            if (inputs) {
                setSaving(true)
                await updatePortalUserProfile(inputs)
                    .then(() => {
                        setProfileOpen(false)
                        setSaving(false)
                    })
                    .catch((e: Error) => {
                        log.warn(e)
                        setSaving(false)
                    })
            }
        }

        const { handleSubmit, handleInputChange, inputs } = useForm(
            saveTeacher,
            portalUser,
        )

        const isIncomplete = Object.values(inputs!).includes("")
        return (
            <Dialog
                onClose={() => setProfileOpen(false)}
                aria-labelledby="profile"
                open={profileOpen}
                className={dialog}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle id="profile">
                    Enter or Update Your Personal Information
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} className={form}>
                        <TextField
                            label={"First Name"}
                            name="firstName"
                            fullWidth={true}
                            autoFocus={true}
                            value={inputs!.firstName ? inputs!.firstName : ""}
                            onChange={handleInputChange}
                            required={true}
                        />
                        <TextField
                            label={"Last Name"}
                            fullWidth={true}
                            name={"lastName"}
                            value={inputs!.lastName ? inputs!.lastName : ""}
                            onChange={handleInputChange}
                            required={true}
                        />
                        <TextField
                            label={"Display Name (For Students)"}
                            fullWidth={true}
                            name={"preferredTitle"}
                            value={
                                inputs!.preferredTitle
                                    ? inputs!.preferredTitle
                                    : ""
                            }
                            onChange={handleInputChange}
                            required={true}
                        />

                        {allowCancel && (
                            <Button
                                variant={"outlined"}
                                style={{
                                    marginTop: portalTheme.spacing(2),
                                    marginRight: portalTheme.spacing(2),
                                }}
                                onClick={() => setProfileOpen(false)}
                            >
                                Cancel
                            </Button>
                        )}

                        <Button
                            variant={"contained"}
                            color={"primary"}
                            disabled={isIncomplete || saving}
                            style={{ marginTop: portalTheme.spacing(2) }}
                            type={"submit"}
                        >
                            Save Profile
                            {saving && (
                                <CircularProgress
                                    size={20}
                                    style={{
                                        marginLeft: portalTheme.spacing(1),
                                    }}
                                />
                            )}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        )
    },
)
