import { Snackbar } from "@material-ui/core"
import { observable } from "mobx"
import { observer } from "mobx-react"
import React from "react"

export class MessageStore {
    @observable
    open = false

    @observable
    message = ""

    displaySnackmessage = (message: string) => {
        this.message = message
        this.open = true
    }
}

export const messageStore = new MessageStore()

export const GlobalSnackmessage = observer(() => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={messageStore.open}
            autoHideDuration={6000}
            message={messageStore.message}
            onClose={() => messageStore.open = false}
        />
    )
})
