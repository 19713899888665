import { computed, observable } from "mobx"
import {
    activityProgressCollection,
    fbFunctions,
    FBTimestampNow,
    FbUtils,
} from "../../config/FirebaseConfig"
import { log } from "../../config/Logging"
import { makeSubscriber, makeSubscriberError } from "../../config/StoreUtils"
import { AllActivityProgress } from "../../shared/models/ActivityProgress"

export class ActivityProgressStore {
    @observable
    activityProgresses = makeSubscriber<AllActivityProgress[]>()

    @observable
    studentActivityProgress = makeSubscriber<AllActivityProgress>()

    @computed
    get progressesNonNull() {
        if (this.activityProgresses.value == null) {
            return []
        }
        return this.activityProgresses.value
    }

    bypassLatestActivity = async (studentId: string) => {
        const studentActivityProgressSnapshot =
            await activityProgressCollection().doc(studentId).get()
        const studentActivityProgress =
            studentActivityProgressSnapshot.get("progress")
        if (studentActivityProgress === undefined) return

        const bypassedActivity = studentActivityProgress.pop()

        return activityProgressCollection()
            .doc(studentId)
            .update({
                progress: [
                    ...studentActivityProgress,
                    {
                        ...bypassedActivity,
                        bypassedByAdmin: true,
                        finish: FBTimestampNow(),
                    },
                ],
            })
    }

    subscribeToActivityProgresses = (classId: string) => {
        if (this.activityProgresses.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to activityProgresses with id ${classId}`)
        this.activityProgresses = { loading: true, subscribed: true }
        const unsubscribe = activityProgressCollection()
            .where("classId", "==", classId)
            .onSnapshot(
                (snapshot) => {
                    const activityProgresses = FbUtils.queryToDocs(
                        snapshot,
                    ) as AllActivityProgress[]
                    this.activityProgresses = makeSubscriber(activityProgresses)
                },
                (error) => {
                    this.activityProgresses = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.activityProgresses = makeSubscriber()
        }
    }

    subscribeToStudentActivityProgress = (studentId: string) => {
        if (this.studentActivityProgress.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to activityProgress with studentId ${studentId}`)
        this.studentActivityProgress = { loading: true, subscribed: true }
        const unsubscribe = activityProgressCollection()
            .doc(studentId)
            .onSnapshot(
                (snapshot) => {
                    const activityProgress = FbUtils.queryDocToDoc(
                        snapshot,
                    ) as AllActivityProgress
                    this.studentActivityProgress =
                        makeSubscriber(activityProgress)
                },
                (error) => {
                    this.studentActivityProgress = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.studentActivityProgress = makeSubscriber()
        }
    }

    updateOverrides = (activityProgresses: AllActivityProgress[]) => {
        return fbFunctions().httpsCallable("updateOverrides")({
            activityProgresses,
        })
    }
}

export const activityProgressStore = new ActivityProgressStore()
