import { FBTimestamp, FirebaseDoc } from "../../config/FirebaseConfig"

export interface Clazz extends FirebaseDoc {
    name: string
    code: string
    classGrade: ClassGrade
    teacherId: string
    created: FBTimestamp
    archived: boolean
}

export enum ClassGrade {
    FIFTH = "FIFTH",
    SIXTH = "SIXTH",
}

export const classGradeName = (grade: ClassGrade) => {
    switch (grade) {
        case ClassGrade.FIFTH:
            return "Fifth Grade"
        case ClassGrade.SIXTH:
            return "Sixth Grade"
        default:
            throw new Error("No grade " + grade)
    }
}
