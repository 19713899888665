import { computed, observable } from "mobx"
import {
    activityProgressCollection,
    fbdb,
    fbFunctions,
    FbUtils,
    studentCollection,
    studentResultsCollection,
} from "../../config/FirebaseConfig"
import { log } from "../../config/Logging"
import { makeSubscriber, makeSubscriberError } from "../../config/StoreUtils"
import { Student } from "../../shared/models/Student"

export class StudentStore {
    @observable
    student = makeSubscriber<Student>()

    @observable
    students = makeSubscriber<Student[]>()

    subscribeToStudents = (classId: string) => {
        if (this.students.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to students with id ${classId}`)
        this.students = { loading: true, subscribed: true }
        const unsubscribe = studentCollection()
            .where("classId", "==", classId)
            .orderBy("lastName")
            .orderBy("firstName")
            .onSnapshot(
                (snapshot) => {
                    const students = FbUtils.queryToDocs(snapshot) as Student[]
                    this.students = makeSubscriber(students)
                },
                (error) => {
                    this.students = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.students = makeSubscriber()
        }
    }

    subscribeToStudent = (studentId: string) => {
        if (this.student.subscribed) {
            return () => {}
        }
        log.debug("Subscribe to student")

        const unsubscribe = studentCollection()
            .doc(studentId)
            .onSnapshot(
                (snapshot) => {
                    if (snapshot.exists) {
                        this.student = makeSubscriber(
                            FbUtils.queryDocToDoc(snapshot) as Student,
                        )
                    } else {
                        this.student = makeSubscriber()
                    }
                },
                (error) => {
                    this.student = makeSubscriberError(error)
                },
            )

        return () => {
            unsubscribe()
            this.student = makeSubscriber()
        }
    }

    updateStudent = async () => {
        if (this.student.value == null) {
            log.warn("Don't try to update a null student!")
            return
        }

        await fbFunctions().httpsCallable("updateStudent")(this.student.value)
    }

    deleteStudent = async (): Promise<void> => {
        if (this.student.value == null) {
            return Promise.reject(new Error("No student loaded"))
        }
        const batch = fbdb.batch()
        const docRefs = [
            studentCollection().doc(this.student.value.id),
            activityProgressCollection().doc(this.student.value.id),
            studentResultsCollection().doc(this.student.value.id),
        ]
        docRefs.forEach((docRef) => batch.delete(docRef))

        try {
            await batch.commit()
            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    updateUniqueScreenname = async () => {
        if (this.student.value && this.student.value.screenname) {
            const result = await studentCollection()
                .where("screenname", "==", this.student.value.screenname)
                .get()
            if (result.empty) {
                await this.updateStudent()
            } else {
                return "Screenname must be unique"
            }
        }
    }

    @computed
    get studentName() {
        return this.student.value == null
            ? ""
            : `${this.student.value.firstName} ${this.student.value.lastName}`
    }

    @computed
    get studentCount() {
        if (this.students.value == null) {
            return undefined
        }
        return this.students.value.length
    }
}

export const studentStore = new StudentStore()
