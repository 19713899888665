import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Typography,
    Theme,
} from "@material-ui/core"
import * as firebaseui from "firebaseui"
import { observer } from "mobx-react"
import * as React from "react"
import { useEffect } from "react"
import { StyledFirebaseAuth } from "react-firebaseui"
import { Redirect } from "react-router-dom"
import { emailAuthProviderId, fbAuth } from "../../config/FirebaseConfig"
import { log } from "../../config/Logging"
import { portalTheme } from "../../config/PortalTheme"
import { PortalUtils } from "../../config/PortalUtils"
import { makeLoader } from "../../config/StoreUtils"
import { Loader } from "../../generic/atoms/Loader"
import { PortalUser } from "../../portal-users/models/PortalUser"
import { portalUserStore } from "../../portal-users/stores/PortalUserStore"
import { RoutePaths } from "../../routing/RoutePaths"
import { authStore } from "../stores/AuthStore"
import ClickCityLogo from "../../generic/images/click-city-logo.png"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        logo: {
            margin: "0 auto",
            width: "min(100vw, 320px)",
            padding: theme.spacing(4),
        },
    }),
)

const uiConfig = (): firebaseui.auth.Config => {
    return {
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            {
                provider: emailAuthProviderId,
                requireDisplayName: false,
            },
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: PortalUtils.baseURL(),
        // Privacy policy url/callback.
        privacyPolicyUrl: function () {
            window.location.assign(PortalUtils.baseURL())
        },
        signInSuccessUrl: PortalUtils.baseURL(),
    }
}

export const LoginOrSignupPage = observer(() => {
    const { page, logo } = useStyles()

    const { authUser } = authStore
    const { portalUser } = portalUserStore

    useEffect(() => {
        log.debug("Listening for auth user changes.")
        return authStore.listenForAuthChangeAndLogin()
    }, [])

    if (portalUser.error) {
        log.debug("Error logging in")
        return (
            <div
                style={{
                    maxWidth: 800,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant={"subtitle1"}
                    color={"error"}
                    style={{ marginBottom: portalTheme.spacing(2) }}
                >
                    {portalUser.error?.message ?? "We couldn't log you in."}
                </Typography>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        fbAuth().signOut()
                        portalUserStore.portalUser = makeLoader<PortalUser>()
                    }}
                >
                    Try again
                </Button>
            </div>
        )
    }

    if (portalUser.loading) {
        log.debug("Portal user is loading, don't display login")
        return <Loader />
    }

    if (authUser != null && portalUser.value != null) {
        return (
            <Redirect
                to={
                    portalUser.value.admin
                        ? RoutePaths.schools
                        : RoutePaths.classesForTeacher(portalUser.value.id)
                }
            />
        )
    }
    return (
        <div className={page}>
            <img
                src={ClickCityLogo}
                className={logo}
                alt={"Click City: Tobacco Logo"}
            />
            <Typography variant="h4">Welcome to Click City: Tobacco</Typography>
            <Box py={2} px={1}>
                <StyledFirebaseAuth
                    uiConfig={uiConfig()}
                    firebaseAuth={fbAuth()}
                />
            </Box>
        </div>
    )
})
