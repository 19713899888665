import * as React from "react"
import { useState } from "react"

// Inspired by https://medium.com/@geeky_writer_/using-react-hooks-to-create-awesome-forms-6f846a4ce57
export const useForm = <T>(callback: () => void, initialValues: T) => {
    const [inputs, setInputs] = useState(initialValues)

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault()
        }
        callback()
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist()
        setInputs((oldInputs) => ({...oldInputs, [event.target.name]: event.target.value}))
    }

    return {
        handleSubmit, handleInputChange, inputs
    }
}