import { sortBy } from "lodash"
import { computed, observable } from "mobx"
import {
    FbUtils,
    portalUserCollection,
    studentCollection,
    activityProgressCollection,
    studentResultsCollection,
    classCollection,
    fbdb,
} from "../../config/FirebaseConfig"
import { log, prettyJson } from "../../config/Logging"
import { makeSubscriber, makeSubscriberError } from "../../config/StoreUtils"
import { PortalUser } from "../models/PortalUser"

export class TeacherStore {
    @observable
    teacher = makeSubscriber<PortalUser>()

    @observable
    teachers = makeSubscriber<PortalUser[]>()

    subscribeToTeacher = (id: string) => {
        if (this.teacher.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to teacher:`, id)
        this.teacher = { loading: true, subscribed: true }
        const unsubscribe = portalUserCollection()
            .doc(id)
            .onSnapshot(
                (snapshot) => {
                    const doc = FbUtils.queryDocToDoc(snapshot) as PortalUser
                    this.teacher = makeSubscriber(doc)
                },
                (error) => {
                    this.teacher = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.teacher = makeSubscriber()
        }
    }

    subscribeToTeachersForSchool = (schoolId: string) => {
        if (this.teachers.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to teachers for school ${schoolId}`)
        this.teachers = { loading: true, subscribed: true }
        const unsubscribe = portalUserCollection()
            .where("schoolId", "==", schoolId)
            .onSnapshot(
                (snapshot) => {
                    const teachers = FbUtils.queryToDocs(
                        snapshot,
                    ) as PortalUser[]
                    log.debug(`Teachers in snapshot ${prettyJson(teachers)}`)

                    const sortedTeachers = sortBy(
                        teachers,
                        (teacher: PortalUser) =>
                            teacher.lastName == null
                                ? teacher.firstName?.toLowerCase()
                                : teacher.lastName.toLowerCase(),
                    )
                    this.teachers = makeSubscriber(sortedTeachers)
                },
                (error) => {
                    log.debug(`Error in teachers ${prettyJson(error)}`)
                    this.teachers = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.teachers = makeSubscriber()
        }
    }
    deleteTeacher = async (): Promise<void> => {
        const batch = fbdb.batch()
        const teacherContainer = portalUserCollection().doc(this.teacherId)
        batch.delete(teacherContainer)
        const classContainer = await classCollection()
            .where("teacherId", "==", this.teacherId)
            .get()
        for (const doc of classContainer.docs) {
            batch.delete(doc.ref)
            const classStudents = await studentCollection()
                .where("classId", "==", doc.id)
                .get()
            const classStudentsProgress = await activityProgressCollection()
                .where("classId", "==", doc.id)
                .get()
            const classStudentsResults = await studentResultsCollection()
                .where("classId", "==", doc.id)
                .get()
            classStudents.forEach(function (doc) {
                batch.delete(doc.ref)
            })
            classStudentsProgress.forEach(function (doc) {
                batch.delete(doc.ref)
            })
            classStudentsResults.forEach(function (doc) {
                batch.delete(doc.ref)
            })
        }

        try {
            await batch.commit()
            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    @computed
    get fullName(): string | undefined {
        if (this.teacher.value == null) {
            return undefined
        }
        return this.teacher.value.fullName
    }

    @computed
    get schoolId(): string | undefined {
        if (this.teacher.value == null) {
            return undefined
        }
        return this.teacher.value.schoolId
    }

    @computed
    get teacherId(): string | undefined {
        if (this.teacher.value == null) {
            return undefined
        }
        return this.teacher.value.id
    }
}

export const teacherStore = new TeacherStore()
