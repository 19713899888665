export enum USState {
    AL = "AL",
    AK = "AK",
    AZ = "AZ",
    AR = "AR",
    CA = "CA",
    CO = "CO",
    CT = "CT",
    DE = "DE",
    FL = "FL",
    GA = "GA",
    HI = "HI",
    ID = "ID",
    IL = "IL",
    IN = "IN",
    IA = "IA",
    KS = "KS",
    KY = "KY",
    LA = "LA",
    ME = "ME",
    MD = "MD",
    MA = "MA",
    MI = "MI",
    MN = "MN",
    MS = "MS",
    MO = "MO",
    MT = "MT",
    NE = "NE",
    NV = "NV",
    NH = "NH",
    NJ = "NJ",
    NM = "NM",
    NY = "NY",
    NC = "NC",
    ND = "ND",
    OH = "OH",
    OK = "OK",
    OR = "OR",
    PA = "PA",
    RI = "RI",
    SC = "SC",
    SD = "SD",
    TN = "TN",
    TX = "TX",
    UT = "UT",
    VT = "VT",
    VA = "VA",
    WA = "WA",
    WV = "WV",
    WI = "WI",
    WY = "WY",
}

export const USStateToName = new Map<USState, string>()

USStateToName.set(USState.AL, "Alabama")
USStateToName.set(USState.AK, "Alaska")
USStateToName.set(USState.AZ, "Arizona")
USStateToName.set(USState.AR, "Arkansas")
USStateToName.set(USState.CA, "California")
USStateToName.set(USState.CO, "Colorado")
USStateToName.set(USState.CT, "Connecticut")
USStateToName.set(USState.DE, "Delaware")
USStateToName.set(USState.FL, "Florida")
USStateToName.set(USState.GA, "Georgia")
USStateToName.set(USState.HI, "Hawaii")
USStateToName.set(USState.ID, "Idaho")
USStateToName.set(USState.IL, "Illinois")
USStateToName.set(USState.IN, "Indiana")
USStateToName.set(USState.IA, "Iowa")
USStateToName.set(USState.KS, "Kansas")
USStateToName.set(USState.KY, "Kentucky")
USStateToName.set(USState.LA, "Louisiana")
USStateToName.set(USState.ME, "Maine")
USStateToName.set(USState.MD, "Maryland")
USStateToName.set(USState.MA, "Massachusetts")
USStateToName.set(USState.MI, "Michigan")
USStateToName.set(USState.MN, "Minnesota")
USStateToName.set(USState.MS, "Mississippi")
USStateToName.set(USState.MO, "Missouri")
USStateToName.set(USState.MT, "Montana")
USStateToName.set(USState.NE, "Nebraska")
USStateToName.set(USState.NV, "Nevada")
USStateToName.set(USState.NH, "New Hampshire")
USStateToName.set(USState.NJ, "New Jersey")
USStateToName.set(USState.NM, "New Mexico")
USStateToName.set(USState.NY, "New York")
USStateToName.set(USState.NC, "North Carolina")
USStateToName.set(USState.ND, "North Dakota")
USStateToName.set(USState.OH, "Ohio")
USStateToName.set(USState.OK, "Oklahoma")
USStateToName.set(USState.OR, "Oregon")
USStateToName.set(USState.PA, "Pennsylvania")
USStateToName.set(USState.RI, "Rhode Island")
USStateToName.set(USState.SC, "South Carolina")
USStateToName.set(USState.SD, "South Dakota")
USStateToName.set(USState.TN, "Tennessee")
USStateToName.set(USState.TX, "Texas")
USStateToName.set(USState.UT, "Utah")
USStateToName.set(USState.VT, "Vermont")
USStateToName.set(USState.VA, "Virginia")
USStateToName.set(USState.WA, "Washington")
USStateToName.set(USState.WV, "West Virginia")
USStateToName.set(USState.WI, "Wisconsin")
USStateToName.set(USState.WY, "Wyoming")
