import {
    Box,
    Button,
    Checkbox,
    Link,
    Popover,
    Typography,
} from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { observer } from "mobx-react"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Loader } from "../../generic/atoms/Loader"
import { RoutePaths } from "../../routing/RoutePaths"
import { lessonFromActivityKey } from "../../shared/models/ActivityKey"
import {
    AllActivityProgress,
    OverrideKeys,
} from "../../shared/models/ActivityProgress"
import { activityProgressStore } from "../stores/ActivityProgressStore"
import { studentStore } from "../stores/StudentStore"
import { EmptyClassInvite } from "./EmptyClassInvite"

const useStyles = makeStyles((theme) =>
    createStyles({
        tablePaper: {
            maxHeight: "70vh",
            overflowY: "auto",
        },
        buttonsBox: {
            display: "flex",
            justifyContent: "flex-end",
            padding: theme.spacing(2),
        },
        typography: {
            padding: theme.spacing(2),
        },
    }),
)

export const StudentTable = observer(() => {
    const classes = useStyles()
    const { activityProgresses } = activityProgressStore
    const { students } = studentStore

    // Component state tracking changes to overrides
    interface StudentTableState {
        activityProgresses: AllActivityProgress[]
        applyingChanges: boolean
        hasError: boolean
        errorMessage: String
    }
    const [state, setState] = React.useState<StudentTableState>({
        activityProgresses: activityProgresses.value ?? [],
        applyingChanges: false,
        hasError: false,
        errorMessage: "",
    })

    if (
        students.loading ||
        students.value === undefined ||
        activityProgresses.loading ||
        activityProgresses.value === undefined ||
        students.value.length === 0
    ) {
        return <EmptyClassInvite />
    }

    // Object with OverrideKeys properties storing booleans indicating
    // whether an override is active for every student
    const allOverridesActive = state.activityProgresses
        .map((studentProgress) => studentProgress.overrides)
        .reduce((prev, curr) => ({
            [OverrideKeys.CONSECUTIVE_DAYS]:
                prev.CONSECUTIVE_DAYS && curr.CONSECUTIVE_DAYS,
            [OverrideKeys.PER_DAY]: prev.PER_DAY && curr.PER_DAY,
            [OverrideKeys.PER_WEEK]: prev.PER_WEEK && curr.PER_WEEK,
        }))

    // Object with OverrideKeys properties storing booleans indicating
    // whether at least one of a given override is active among all students
    const oneOrMoreOverridesActive = state.activityProgresses
        .map((studentProgress) => studentProgress.overrides)
        .reduce((prev, curr) => ({
            [OverrideKeys.CONSECUTIVE_DAYS]:
                prev.CONSECUTIVE_DAYS || curr.CONSECUTIVE_DAYS,
            [OverrideKeys.PER_DAY]: prev.PER_DAY || curr.PER_DAY,
            [OverrideKeys.PER_WEEK]: prev.PER_WEEK || curr.PER_WEEK,
        }))

    // Boolean indicating whether there are differences between the
    // component state and ActivityProgressStore
    const overridesHaveChanges = state.activityProgresses
        .map((studentProgress) => {
            const dbOverrides = activityProgresses.value?.find(
                (a) => a.id === studentProgress.id,
            )
            if (dbOverrides?.overrides === undefined) return true

            return (
                studentProgress.overrides.CONSECUTIVE_DAYS !==
                    dbOverrides.overrides.CONSECUTIVE_DAYS ||
                studentProgress.overrides.PER_DAY !==
                    dbOverrides.overrides.PER_DAY ||
                studentProgress.overrides.PER_WEEK !==
                    dbOverrides.overrides.PER_WEEK
            )
        })
        .reduce((prev, curr) => prev || curr)

    // Toggles override for all students in component state
    const toggleForAllStudents = (override: OverrideKeys) => {
        const newActivityProgresses = state.activityProgresses.map(
            (studentProgress) => ({
                ...studentProgress,
                overrides: {
                    ...studentProgress.overrides,
                    [override]: !oneOrMoreOverridesActive[override],
                },
            }),
        )
        setState({
            ...state,
            activityProgresses: newActivityProgresses,
        })
    }

    // Toggles override for a given student in component state
    const toggleForStudent = (
        id: string | undefined,
        override: OverrideKeys,
    ) => {
        if (id === undefined) return
        const newActivityProgresses = state.activityProgresses.map(
            (studentProgress) => {
                if (studentProgress.id === id) {
                    return {
                        ...studentProgress,
                        overrides: {
                            ...studentProgress.overrides,
                            [override]: !studentProgress.overrides[override],
                        },
                    }
                } else {
                    return studentProgress
                }
            },
        )
        setState({
            ...state,
            activityProgresses: newActivityProgresses,
        })
    }

    // Overwrites component state with ActivityProgressStore
    const discardChanges = () => {
        if (activityProgresses.value) {
            setState({
                ...state,
                activityProgresses: activityProgresses.value,
            })
        }
    }

    // Overwrites ActivityProgressStore state with component state
    const applyChanges = async () => {
        setState({
            ...state,
            applyingChanges: true,
        })

        try {
            await activityProgressStore.updateOverrides(
                state.activityProgresses,
            )
            // update was successful
            setState({
                ...state,
                applyingChanges: false,
            })
        } catch (error) {
            // update failed
            setState({
                ...state,
                applyingChanges: false,
                hasError: true,
                errorMessage: "ERROR: Overrides could not be updated",
            })
        }
    }

    const closePopout = () => {
        setState({
            ...state,
            hasError: false,
        })
    }

    const overrideLabels = {
        [OverrideKeys.CONSECUTIVE_DAYS]: "Allow consecutive days of lessons",
        [OverrideKeys.PER_DAY]: "Allow more than one lesson per day",
        [OverrideKeys.PER_WEEK]:
            "Allow more than two days of lessons each week",
    }

    const tableHeadCheckboxes = Object.keys(OverrideKeys).map((key) => {
        const override = key as OverrideKeys
        return (
            <TableCell key={override}>
                <p>{overrideLabels[override]}</p>
                <Checkbox
                    checked={
                        allOverridesActive[override] ||
                        oneOrMoreOverridesActive[override]
                    }
                    indeterminate={
                        !allOverridesActive[override] &&
                        oneOrMoreOverridesActive[override]
                    }
                    onClick={() => toggleForAllStudents(override)}
                />
            </TableCell>
        )
    })

    const tableHead = (
        <TableHead>
            <TableRow>
                <TableCell>Student Name</TableCell>
                <TableCell>Screenname</TableCell>
                <TableCell>Student Id</TableCell>
                <TableCell>Date of Birth</TableCell>
                <TableCell>Current Lesson</TableCell>
                {tableHeadCheckboxes}
            </TableRow>
        </TableHead>
    )

    const studentRows = students.value.map((student, idx) => {
        let activity = ""
        let startedOrFinished = ""
        const studActProg = state.activityProgresses.find(
            (progress) => progress.id === student.id,
        )
        if (studActProg === undefined) return null
        if (studActProg.progress.length > 0) {
            let lastProgress =
                studActProg.progress[studActProg.progress.length - 1]
            activity = lastProgress.activity
            startedOrFinished = lastProgress.finish ? "finished" : "started"
        }

        const studentCheckboxes = Object.keys(OverrideKeys).map((key) => {
            const override = key as OverrideKeys
            return (
                <TableCell key={override}>
                    <Checkbox
                        checked={studActProg.overrides[override]}
                        onClick={() =>
                            toggleForStudent(studActProg.id, override)
                        }
                    />
                </TableCell>
            )
        })
        return (
            <TableRow key={idx}>
                <TableCell>
                    <Link
                        component={RouterLink}
                        to={RoutePaths.studentPage(student.classId, student.id)}
                    >
                        {student.lastName}, {student.firstName}
                    </Link>
                </TableCell>
                <TableCell>{student.screenname}</TableCell>
                <TableCell>{student.studentId}</TableCell>
                <TableCell>
                    {student.birthCode.slice(0, 2) +
                        "/" +
                        student.birthCode.slice(2, student.birthCode.length)}
                </TableCell>
                <TableCell>
                    <div>
                        {activity === "" ? "Not started" : "Lesson "}
                        {lessonFromActivityKey(activity)} {startedOrFinished}
                    </div>
                </TableCell>
                {studentCheckboxes}
            </TableRow>
        )
    })
    return (
        <div>
            <Paper className={classes.tablePaper}>
                <Table stickyHeader>
                    {tableHead}
                    <TableBody>{studentRows}</TableBody>
                </Table>
            </Paper>

            <Popover
                open={state.hasError}
                onClose={closePopout}
                anchorReference={"anchorPosition"}
                anchorPosition={{
                    left: 0,
                    top: 0,
                }}
            >
                <Typography className={classes.typography} color="error">
                    {state.errorMessage}
                </Typography>
            </Popover>

            <Box className={classes.buttonsBox}>
                <Button
                    variant="text"
                    disableElevation
                    disabled={!overridesHaveChanges}
                    onClick={() => discardChanges()}
                >
                    Discard changes
                </Button>
                {state.applyingChanges && <Loader />}
                <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    disabled={state.applyingChanges || !overridesHaveChanges}
                    onClick={() => applyChanges()}
                >
                    Apply changes
                </Button>
            </Box>
        </div>
    )
})
