import { observer } from "mobx-react"
import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { authStore } from "../auth/stores/AuthStore"
import { log } from "../config/Logging"
import { Loader } from "../generic/atoms/Loader"
import { ErrorPage } from "../portal-users/organisms/ErrorPage"
import { portalUserStore } from "../portal-users/stores/PortalUserStore"
import { RoutePaths } from "./RoutePaths"

export const AuthenticatedRoute = observer((props: RouteProps & { adminOnly?: boolean }) => {
    const {authUser, loggingIn} = authStore
    const {portalUser} = portalUserStore
    const authenticated = authUser != null
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {component, adminOnly, ...otherProps} = props

    if (portalUser.error) {
        return <ErrorPage message={portalUser.error.message}/>
    } else if (authenticated) {

        if (portalUser.value == null) {
            return <Loader/>
        } else {
            const isAdmin = portalUser.value?.admin
            if (isAdmin || !adminOnly) {
                log.debug(`Authenticated in path: ${props.path}.`)
                return <Route {...props}/>
            } else {
                return <ErrorPage message={"You are not authorized for this page."}/>
            }
        }
    } else {
        if (loggingIn) {
            log.debug("In authenticated route, logging in.")
            return <Loader/>
        } else {
            log.debug("Redirect unauthed user to login")
            return (
                <Route
                    render={() => {
                        log.debug("Redirecting unauthenticated user to login.")
                        return <Redirect to={RoutePaths.login}/>
                    }}
                    {...otherProps}
                />
            )
        }
    }
})
