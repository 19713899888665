import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core"
import { DeleteForever } from "@material-ui/icons"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Loader } from "../../generic/atoms/Loader"
import { RoutePaths } from "../../routing/RoutePaths"
import { teacherStore } from "../stores/TeacherStore"
import { userInviteStore } from "../stores/UserInviteStore"

export const TeachersTable = observer((props: { schoolId: string }) => {
    const { schoolId } = props
    const { teachers } = teacherStore
    const { invites, deleteInvite } = userInviteStore
    useEffect(() => {
        const cleanup = teacherStore.subscribeToTeachersForSchool(schoolId)
        const cleanup2 = userInviteStore.subscribeToInvitesForSchool(schoolId)
        return () => {
            cleanup()
            cleanup2()
        }
    }, [schoolId])

    if (
        teachers.loading ||
        teachers.value == null ||
        invites.loading ||
        invites.value == null
    ) {
        return <Loader />
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>TEACHER NAME</TableCell>
                    <TableCell>EMAIL</TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {invites.value.map((invite) => (
                    <TableRow key={invite.id}>
                        <TableCell>{invite.name}</TableCell>
                        <TableCell>{invite.email}</TableCell>
                        <TableCell>Pending</TableCell>
                        <TableCell>
                            <IconButton
                                size="small"
                                onClick={() => deleteInvite(invite.email)}
                            >
                                <DeleteForever />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                {teachers.value.map((teacher) => (
                    <TableRow key={teacher.id}>
                        <TableCell>
                            <Link to={RoutePaths.classesForTeacher(teacher.id)}>
                                {teacher.lastName}, {teacher.firstName}
                            </Link>
                        </TableCell>
                        <TableCell>{teacher.email}</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
})
