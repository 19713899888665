import { observer } from "mobx-react"
import React from "react"
import { Loader } from "../../generic/atoms/Loader"
import { SaveableTextField } from "../../generic/molecules/SaveableTextField"
import { studentStore } from "../stores/StudentStore"

export const EditStudent = observer(() => {

    const {student, updateStudent, updateUniqueScreenname } = studentStore

    if (student.loading || student.value == null) {
        return <Loader/>
    }

    return (
        <div>
            <SaveableTextField
                title={"Screen Name"}
                value={student.value.screenname}
                update={screenname => student.value!.screenname = screenname.trim().substring(0, 25)}
                save={updateUniqueScreenname}
            />
            <SaveableTextField
                title={"Birthday"}
                value={student.value.birthCode}
                update={birthCode => student.value!.birthCode = birthCode.slice(0, 2) + birthCode.slice(3, 5)}
                save={updateStudent}
                valid={birthcode => /(^0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]$)/.test(birthcode)}
                mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                toMasked={value => value.slice(0, 2) + "/" + value.slice(2, 4)}
            />
            <SaveableTextField
                title={"Student ID"}
                value={student.value.studentId}
                update={studentId => student.value!.studentId = studentId.trim()}
                save={updateStudent}
            />
        </div>
    )
})
