import { sortBy } from "lodash"
import { observable } from "mobx"
import { FbUtils, userInviteCollection } from "../../config/FirebaseConfig"
import { log, prettyJson } from "../../config/Logging"
import { makeSubscriber, makeSubscriberError } from "../../config/StoreUtils"
import { messageStore } from "../../generic/stores/MessageStore"
import { UserInvite } from "../models/UserInvite"

export class UserInviteStore {
    @observable
    invites = makeSubscriber<UserInvite[]>()

    saveInvite = async (invite: UserInvite) => {
        const cleaned = this.cleanInvite(invite)
        const preexisting = await this.findUserInvite(cleaned.email)
        if (preexisting != null) {
            messageStore.displaySnackmessage(
                `There was already an invite for ${cleaned.email}`,
            )
            return
        }
        await userInviteCollection().add(cleaned)
        messageStore.displaySnackmessage("Invite saved for " + invite.email)
    }

    deleteInvite = async (email: string) => {
        const inviteSnapshot = await userInviteCollection()
            .where("email", "==", email.toLowerCase())
            .get()

        if (inviteSnapshot.empty) {
            messageStore.displaySnackmessage(
                `Unable to locate invite for ${email}`,
            )
        } else {
            try {
                await inviteSnapshot.docs[0].ref.delete()
                messageStore.displaySnackmessage(`Deleted invite for ${email}`)
            } catch (error) {
                messageStore.displaySnackmessage(`Error: ${error}`)
            }
        }
    }

    findUserInvite = async (email: string) => {
        const inviteSnapshot = await userInviteCollection()
            .where("email", "==", email.toLowerCase())
            .get()

        return FbUtils.queryToSingleDoc(inviteSnapshot) as
            | UserInvite
            | undefined
    }

    subscribeToInvitesForSchool = (schoolId: string) => {
        if (this.invites.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to invites for school ${schoolId}`)
        this.invites = { loading: true, subscribed: true }
        const unsubscribe = userInviteCollection()
            .where("schoolId", "==", schoolId)
            .onSnapshot(
                (snapshot) => {
                    const invites = FbUtils.queryToDocs(
                        snapshot,
                    ) as UserInvite[]
                    log.debug(`invites in snapshot ${prettyJson(invites)}`)

                    const sortedInvites = sortBy(
                        invites,
                        (invite: UserInvite) => invite.email,
                    )
                    this.invites = makeSubscriber(sortedInvites)
                },
                (error) => {
                    log.debug(`Error in teachers ${prettyJson(error)}`)
                    this.invites = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.invites = makeSubscriber()
        }
    }

    private cleanInvite = (invite: UserInvite): UserInvite => {
        const { email, ...rest } = invite
        return {
            email: email.trim().toLowerCase(),
            ...rest,
        }
    }
}

export const userInviteStore = new UserInviteStore()
