import { makeStyles } from "@material-ui/core"

export const useGenericStyles = makeStyles(theme => ({
    marginRight: {
        marginRight: theme.spacing(2)
    },
    marginLeft: {
        marginLeft: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    marginTop1: {
        marginTop: theme.spacing(1)
    },
    flex: {
        display: "flex"
    },
    columnCentered: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    grow: {
        flexGrow: 1
    },
    surface: {
        backgroundColor: theme.palette.background.paper
    }
}))

