import { Box } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import styled from "@material-ui/core/styles/styled"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"
import React from "react"
import { classStore } from "../../classes/stores/ClassStore"
import { studentJoinUrl } from "../../config/PortalUtils"

const StyledInvite = styled(Box)(({theme}) => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(8),
        "& p": {
            lineHeight: 2
        }
    })
)

export const EmptyClassInvite: React.FC = observer(() => {
    const {clazz} = classStore

    return (
        <StyledInvite>
            <Typography variant="h5">You have no students</Typography>
            <Typography variant={"body2"}>Students can go to
                <Link href={studentJoinUrl}>
                    {" "}app.clickcity.org/join-class
                </Link>
            </Typography>
            {clazz && (
                <Typography variant={"body2"}>
                    And enter the class code
                    <Link href={studentJoinUrl}> {clazz.value?.code?.toUpperCase()} </Link>
                    to join your class
                </Typography>
            )}
        </StyledInvite>
    )
})
