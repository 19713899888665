import { sortBy } from "lodash"
import { computed, observable } from "mobx"
import {
    classCollection,
    FbUtils,
    fbdb,
    activityProgressCollection,
    studentCollection,
    studentResultsCollection,
    FBTimestampNow,
} from "../../config/FirebaseConfig"
import { log, prettyJson } from "../../config/Logging"
import { PortalUtils } from "../../config/PortalUtils"
import { makeSubscriber, makeSubscriberError } from "../../config/StoreUtils"
import { ClassGrade, Clazz } from "../../shared/models/Clazz"

export class ClassStore {
    @observable
    clazz = makeSubscriber<Clazz>()

    @observable
    activeClasses = makeSubscriber<Clazz[]>()

    @observable
    archivedClasses = makeSubscriber<Clazz[]>()

    subscribeToClassesForTeacher = (teacherId: string) => {
        if (this.activeClasses.subscribed && this.archivedClasses.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to classes for teacher ${teacherId}`)
        this.activeClasses = { loading: true, subscribed: true }
        this.archivedClasses = { loading: true, subscribed: true }
        const unsubscribe = classCollection()
            .where("teacherId", "==", teacherId)
            .orderBy("name")
            .onSnapshot(
                (snapshot) => {
                    const classes = FbUtils.queryToDocs(snapshot) as Clazz[]
                    log.debug(`Classes in snapshot ${prettyJson(classes)}`)
                    const activeClasses = [] as Clazz[]
                    const archivedClasses = [] as Clazz[]
                    classes.forEach((element) => {
                        if (element.archived) {
                            archivedClasses.push(element)
                        } else {
                            activeClasses.push(element)
                        }
                    })

                    const sortedActiveClasses = sortBy(
                        activeClasses,
                        (clazz: Clazz) => clazz.name,
                    )
                    this.activeClasses = makeSubscriber(sortedActiveClasses)
                    const sortedArchivedClasses = sortBy(
                        archivedClasses,
                        (clazz: Clazz) => clazz.name,
                    )
                    this.archivedClasses = makeSubscriber(sortedArchivedClasses)
                },
                (error) => {
                    log.debug(`Error in classes ${prettyJson(error)}`)
                    this.activeClasses = makeSubscriberError(error)
                    this.archivedClasses = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.activeClasses = makeSubscriber()
            this.archivedClasses = makeSubscriber()
        }
    }

    subscribeToClass = (classId: string) => {
        if (this.clazz.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to class ${classId}`)
        this.clazz = { loading: true, subscribed: true }
        const unsubscribe = classCollection()
            .doc(classId)
            .onSnapshot(
                (snapshot) => {
                    this.clazz = makeSubscriber(
                        FbUtils.queryDocToDoc(snapshot) as Clazz,
                    )
                },
                (error) => {
                    log.debug(`Error in class ${prettyJson(error)}`)
                    this.clazz = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.clazz = makeSubscriber()
        }
    }

    createClass = async (
        teacherId: string,
        name: string,
        classGrade: ClassGrade,
    ) => {
        let currentLength = 6
        let code = PortalUtils.generateId(currentLength)
        const clazz: Clazz = {
            name,
            teacherId,
            classGrade,
            code,
            created: FBTimestampNow(),
            archived: false,
        }
        const classRef = await classCollection().add(clazz)

        // TODO Do this with a cloud function
        let verifyingUniqueId = true
        while (verifyingUniqueId) {
            currentLength++
            const classes = await classCollection()
                .where("code", "==", code)
                .get()

            if (classes.size > 1) {
                log.debug(`${classes.size} classes with code ${code}`)
                code = PortalUtils.generateId(currentLength)
                clazz.code = code
                await classRef.set({ code }, { merge: true })
            } else {
                log.debug(
                    `Only ${classes.size} classes with code, so it's good!`,
                )
                verifyingUniqueId = false
            }
        }

        return (await FbUtils.queryDocToDoc(await classRef.get())) as Clazz
    }

    updateClass = async (classId: string, name: string) => {
        await classCollection().doc(classId).set(
            {
                name,
            },
            {
                merge: true,
            },
        )
    }

    updateClassArchive = async (classId: string, archived: boolean) => {
        await classCollection().doc(classId).set(
            {
                archived,
            },
            {
                merge: true,
            },
        )
    }

    deleteClass = async (): Promise<void> => {
        try {
            await this.deleteStudents()
            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    deleteStudents = async (): Promise<void> => {
        if (this.clazz.value == null) {
            return Promise.reject(new Error("No class loaded"))
        }
        const classStudents = await studentCollection()
            .where("classId", "==", this.classId)
            .get()
        const classStudentsProgress = await activityProgressCollection()
            .where("classId", "==", this.classId)
            .get()
        const classStudentsResults = await studentResultsCollection()
            .where("classId", "==", this.classId)
            .get()
        const classContainer = classCollection().doc(this.clazz.value.id)
        const batch = fbdb.batch()
        batch.delete(classContainer)
        classStudents.forEach(function (doc) {
            batch.delete(doc.ref)
        })
        classStudentsProgress.forEach(function (doc) {
            batch.delete(doc.ref)
        })
        classStudentsResults.forEach(function (doc) {
            batch.delete(doc.ref)
        })

        try {
            await batch.commit()
            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    @computed
    get classId(): string | undefined {
        if (this.clazz.value == null) {
            return undefined
        }
        return this.clazz.value.id
    }
}

export const classStore = new ClassStore()
