import { computed, observable } from "mobx"
import { AuthUser } from "../../auth/models/AuthUser"
import {
    fbFunctions,
    FbUtils,
    portalUserCollection,
} from "../../config/FirebaseConfig"
import { log } from "../../config/Logging"
import { makeLoader } from "../../config/StoreUtils"
import { PortalUser } from "../models/PortalUser"

export class PortalUserStore {
    @observable
    portalUser = makeLoader<PortalUser>()

    clearPortalUser = () => (this.portalUser = makeLoader<PortalUser>())

    updatePortalUserProfile = async (user: PortalUser) => {
        user.fullName = `${user.firstName} ${user.lastName}`
        if (this.portalUser.value) {
            this.portalUser.value.fullName = user.fullName
        }
        return fbFunctions().httpsCallable("updateTeacherProfile")({
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user.firstName} ${user.lastName}`,
            preferredTitle: user.preferredTitle,
        })
    }

    findOrCreatePortalUser = async (user: AuthUser) => {
        this.portalUser.loading = true
        const id = user.uid

        log.debug("Find portal user with " + id)
        let currentUser: PortalUser
        const portalUserDoc = await portalUserCollection().doc(id).get()
        if (portalUserDoc.exists) {
            currentUser = FbUtils.queryDocToDoc(portalUserDoc) as PortalUser
        } else {
            if (user.email == null) {
                this.portalUser.error = Error(
                    "Please make sure you sign up with an email.",
                )
                return
            }

            log.debug(
                "create portal user with cloud function with email " +
                    user.email,
            )

            try {
                await fbFunctions().httpsCallable("createTeacherFromInvite")(
                    user.email,
                )
                log.debug(
                    "created portal user with cloud function with email " +
                        user.email,
                )
                const portalUserDoc = await portalUserCollection().doc(id).get()
                currentUser = FbUtils.queryDocToDoc(portalUserDoc) as PortalUser
            } catch (error) {
                this.portalUser.error = Error(
                    "This email doesn't appear to have a teacher invite. Please verify it's correct and try again.",
                )
                return
            }
        }
        this.portalUser.loading = false
        this.portalUser.value = currentUser
        log.debug("portal user set, invite deleted")
    }

    @computed
    get teacherName(): string | undefined {
        if (this.portalUser.value == null || !this.portalUser.value.admin) {
            return undefined
        }
        return this.portalUser.value.fullName
    }
}

export const portalUserStore = new PortalUserStore()
