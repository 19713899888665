import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { authStore } from "./auth/stores/AuthStore"

authStore.listenForAuthChangeAndLogin()

export const baseURL = window.location.origin

ReactDOM.render(<App/>, document.getElementById("root"))
