import { observable } from "mobx"
import { fbAuth } from "../../config/FirebaseConfig"
import { log } from "../../config/Logging"
import { portalUserStore } from "../../portal-users/stores/PortalUserStore"
import { AuthUser } from "../models/AuthUser"

export class AuthStore {
    @observable
    authUser?: AuthUser

    @observable
    loggingIn = true

    listenForAuthChangeAndLogin = () => {
        this.loggingIn = true
        return fbAuth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then(async () => {
                    const displayName = user.displayName
                        ? user.displayName
                        : undefined
                    const email = user.email ? user.email : undefined
                    const emailVerified = user.emailVerified
                    const photoURL = user.photoURL ? user.photoURL : undefined
                    const uid = user.uid
                    const authUser = {
                        displayName,
                        email,
                        emailVerified,
                        photoURL,
                        uid,
                    }
                    this.authUser = authUser
                    this.loggingIn = false
                    log.info(`Logged in as ${email}.`)
                    await portalUserStore.findOrCreatePortalUser(authUser)
                })
            } else {
                log.debug("auth state change user is null")
                this.authUser = undefined
                this.loggingIn = false
            }
        })
    }
}

export const authStore = new AuthStore()
