import { Button, IconButton } from "@material-ui/core"
import { ButtonProps } from "@material-ui/core/Button"
import { IconButtonProps } from "@material-ui/core/IconButton"
import React from "react"
import { Link, LinkProps } from "react-router-dom"

const InnerLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link innerRef={ref} {...props} />
))

// @ts-ignore
export const LinkButton = (props: ButtonProps & LinkProps) => <Button {...props} component={InnerLink}/>

// @ts-ignore
export const LinkIconButton = (props: IconButtonProps & LinkProps) => <IconButton {...props} component={InnerLink}/>
