export enum ActivityKey {

    /////
    // Grade 5, Lesson 1
    /////

    ECIG_101 = "ECIG_101",
    TOBACCO_TOUR = "TOBACCO_TOUR",

    /////
    // Grade 5, Lesson 2
    /////

    KIDS_CHOICE = "KIDS_CHOICE",
    MAKE_YOUR_OWN_VAPER_PT_1 = "MAKE_YOUR_OWN_VAPER_PT_1",
    KIDS_CHOICE_2 = "KIDS_CHOICE_2",
    REALITY_CHECK = "REALITY_CHECK",

    /////
    // Grade 5, Lesson 3
    /////

    MAKE_YOUR_OWN_VAPER_PT_2 = "MAKE_YOUR_OWN_VAPER_PT_2",
    ADDICTION_MAIN = "ADDICTION_MAIN",
    ADDICTION_PONG = "ADDICTION_PONG",

    /////
    // Grade 5, Lesson 4
    /////

    CAMP_CRAVINGS = "CAMP_CRAVINGS",
    PERSONALITY = "PERSONALITY",

    /////
    // Grade 5, Lesson 5
    /////

    SECOND_HAND = "SECOND_HAND",
    SUPER_HERO = "SUPER_HERO",
    TRUTH_OR_DARE = "TRUTH_OR_DARE",

    /////
    // Grade 5, Lesson 6
    /////

    MAKE_A_VIDEO = "MAKE_A_VIDEO",
    WHEEL_OF_MISFORTUNE = "WHEEL_OF_MISFORTUNE",
    EVERY_CIG = "EVERY_CIG",

    /////
    // Grade 5, Lesson 7
    /////

    DEFINITION = "DEFINITION",
    MAZE = "MAZE",
    EVERY_CIG_2 = "EVERY_CIG_2",

    /////
    // Grade 5, Lesson 8
    /////

    TIME_MACHINE = "TIME_MACHINE",
    SUMMARY = "SUMMARY",
    PLAYGROUND = "PLAYGROUND",

    /////
    // Grade 6, Lesson 1
    /////

    ECIG_201 = "ECIG_201",
    MYSTERY = "MYSTERY",
    DR_TIME= "DR_TIME",

    /////
    // Grade 6, Lesson 2
    /////

    ADDICTION_201 = "ADDICTION_201",
    ADDICTION_ROAD = "ADDICTION_ROAD",
    MAKE_A_FRIEND = "MAKE_FRIEND",
    SUMMARY_6 = "SUMMARY_6"
}

export interface Lesson {
    name: string
    activities: ActivityKey[]
}

export const Lessons5thGrade: Lesson[] = [
    {
        name: "1",
        activities: [ActivityKey.ECIG_101, ActivityKey.TOBACCO_TOUR]
    },
    {
        name: "2",
        activities: [ActivityKey.KIDS_CHOICE, ActivityKey.MAKE_YOUR_OWN_VAPER_PT_1, ActivityKey.KIDS_CHOICE_2, ActivityKey.REALITY_CHECK]
    },
    {
        name: "3",
        activities: [ActivityKey.MAKE_YOUR_OWN_VAPER_PT_2, ActivityKey.ADDICTION_MAIN, ActivityKey.ADDICTION_PONG]
    },
    {
        name: "4",
        activities: [ActivityKey.CAMP_CRAVINGS, ActivityKey.PERSONALITY]
    },
    {
        name: "5",
        activities: [ActivityKey.SECOND_HAND, ActivityKey.SUPER_HERO, ActivityKey.TRUTH_OR_DARE]
    },
    {
        name: "6",
        activities: [ActivityKey.MAKE_A_VIDEO, ActivityKey.WHEEL_OF_MISFORTUNE, ActivityKey.EVERY_CIG]
    },
    {
        name: "7",
        activities: [ActivityKey.DEFINITION, ActivityKey.MAZE, ActivityKey.EVERY_CIG_2]
    },
    {
        name: "8",
        activities: [ActivityKey.TIME_MACHINE, ActivityKey.SUMMARY, ActivityKey.PLAYGROUND]
    }
]

export const Lessons6thGrade: Lesson[] = [
    {
        name: "1",
        activities: [ActivityKey.ECIG_201, ActivityKey.MYSTERY, ActivityKey.DR_TIME]
    },
    {
        name: "2",
        activities: [ActivityKey.ADDICTION_201, ActivityKey.ADDICTION_ROAD, ActivityKey.MAKE_A_FRIEND, ActivityKey.SUMMARY_6]
    },
]

export const activityNameFromKey: Map<ActivityKey, string> = new Map([
    [ActivityKey.ECIG_101, "E-cig 101"],
    [ActivityKey.TOBACCO_TOUR, "Tobacco Tour"],
    [ActivityKey.KIDS_CHOICE, "Kid’s Choice, Part 1"],
    [ActivityKey.MAKE_YOUR_OWN_VAPER_PT_1, "Make your Own Vaper, Part 1"],
    [ActivityKey.KIDS_CHOICE_2, "Kid’s Choice, Part 2"],
    [ActivityKey.REALITY_CHECK, "Reality Check"],
    [ActivityKey.MAKE_YOUR_OWN_VAPER_PT_2, "Make Your Own Vaper, Part 2"],
    [ActivityKey.ADDICTION_MAIN, "Addiction 101"],
    [ActivityKey.ADDICTION_PONG, "Addiction Pong"],
    [ActivityKey.CAMP_CRAVINGS, "Camp Cravings"],
    [ActivityKey.PERSONALITY, "Personality Quiz"],
    [ActivityKey.SECOND_HAND, "Secondhand Smoke & Vapor Lab"],
    [ActivityKey.SUPER_HERO, "Superhero"],
    [ActivityKey.TRUTH_OR_DARE, "Truth or Dare"],
    [ActivityKey.MAKE_A_VIDEO, "Make a Video"],
    [ActivityKey.WHEEL_OF_MISFORTUNE, "Wheel of Misfortune"],
    [ActivityKey.EVERY_CIG, "Every Cigarette Does, Part 1"],
    [ActivityKey.DEFINITION, "Definition of a Smoker"],
    [ActivityKey.MAZE, "Addiction Maze"],
    [ActivityKey.EVERY_CIG_2, "Every Cigarette Does, Part 2"],
    [ActivityKey.TIME_MACHINE, "Time Machine"],
    [ActivityKey.SUMMARY, "Summary Activity"],
    [ActivityKey.PLAYGROUND, "Playground"],
    [ActivityKey.ECIG_201, "E-cig 201"],
    [ActivityKey.MYSTERY, "Mystery Game"],
    [ActivityKey.DR_TIME, "Dr. Time"],
    [ActivityKey.ADDICTION_201, "Addiction 201"],
    [ActivityKey.ADDICTION_ROAD, "Addiction Road"],
    [ActivityKey.MAKE_A_FRIEND, "Make a Friend"],
    [ActivityKey.SUMMARY_6, "Summary"],
])

export const lessonFromActivityKey = (activityKey: string): string => {
    if (!!activityKey) {
        let lessons = Lessons5thGrade.filter(lesson => lesson.activities.map(value => value.toString()).indexOf(activityKey) !== -1)
        if (lessons.length === 0) {
            lessons = Lessons6thGrade.filter(lesson => lesson.activities.map(value => value.toString()).indexOf(activityKey) !== -1)
        }
        if (lessons.length > 0) {
            return lessons[0].name;
        }
    }
    return activityKey;
}