import { makeStyles } from "@material-ui/core"
import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { LoginOrSignupPage } from "../auth/pages/LoginOrSignupPage"
import { ClassListPage } from "../classes/pages/ClassListPage"
import { TeacherBar } from "../generic/molecules/TeacherBar"
import ResourcesPage from "../resources/pages/ResourcesPage"
import { InviteTeacherPage } from "../portal-users/pages/InviteTeacherPage"
import { EditSchoolForm } from "../schools/pages/EditSchoolForm"
import { SchoolDetailsPage } from "../schools/pages/SchoolDetailsPage"
import { SchoolsPage } from "../schools/pages/SchoolsPage"
import { StudentListPage } from "../students/pages/StudentListPage"
import { StudentPage } from "../students/pages/StudentPage"
import { AuthenticatedRoute } from "./AuthenticatedRoute"
import { RoutePaths } from "./RoutePaths"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    main: {
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
    },
}))

export const CCPRouter = () => {
    const { root, main } = useStyles()
    return (
        <BrowserRouter>
            <div className={root}>
                <TeacherBar />
                <main className={main}>
                    <Switch>
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.studentPage()}
                            component={StudentPage}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.viewClass()}
                            component={StudentListPage}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.classesForTeacher()}
                            component={ClassListPage}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.resources}
                            component={ResourcesPage}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.schools}
                            component={SchoolsPage}
                            adminOnly={true}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.createSchool}
                            component={EditSchoolForm}
                            adminOnly={true}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.editSchool()}
                            component={EditSchoolForm}
                            adminOnly={true}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.schoolDetails()}
                            component={SchoolDetailsPage}
                            adminOnly={true}
                        />
                        <AuthenticatedRoute
                            exact
                            path={RoutePaths.inviteTeacher()}
                            component={InviteTeacherPage}
                            adminOnly={true}
                        />
                        <Route
                            path={RoutePaths.login}
                            component={LoginOrSignupPage}
                        />
                        <Route
                            path={RoutePaths.home}
                            component={LoginOrSignupPage}
                        />
                    </Switch>
                </main>
            </div>
        </BrowserRouter>
    )
}
