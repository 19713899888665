import { sample } from "lodash"
import { baseURL } from "../index"
import { log } from "./Logging"

const idCharacterOptions = "ABCDEFGHIJKLMNPQRTUVWXYZ2346789".toLowerCase().split("")

export class PortalUtils {

    /**
     * length defaults to 6
     * @param length
     */
    static generateId = (length?: number) => {
        let id = ""
        while (id.length < (length == null ? 6 : length)) {
            id += sample(idCharacterOptions)
        }
        return id
    }

    static baseURL = () => {
        log.debug(`Base url is: ${baseURL}`)
        return baseURL
    }

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumValues = <T extends EnumType>(enunn: any): T[] => {
    return Object.keys(enunn).filter(key => isNaN(+key)).map(name => enunn[name]) as T[]
}

type EnumType = string | number

export enum PortalEnv {
    LOCAL_DEV,
    DEV,
    PROD
}

export const studentJoinUrl = "https://app.clickcity.org/join-class"
export const portalUrl = "https://portal.clickcity.org"
