import { sortBy } from "lodash"
import { observable, computed } from "mobx"
import {
    FbUtils,
    schoolCollection,
    portalUserCollection,
    studentCollection,
    activityProgressCollection,
    studentResultsCollection,
    classCollection,
    userInviteCollection,
    fbdb,
} from "../../config/FirebaseConfig"
import { log, prettyJson } from "../../config/Logging"
import { makeSubscriber, makeSubscriberError } from "../../config/StoreUtils"
import { School } from "../models/School"

export class SchoolStore {
    @observable
    school = makeSubscriber<School>()

    @observable
    createNewSchool?: School

    @observable
    schools = makeSubscriber<School[]>()

    @observable
    schoolSaved = false

    subscribeToSchools = () => {
        if (this.schools.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to schools`)
        this.schools = { loading: true, subscribed: true }
        const unsubscribe = schoolCollection().onSnapshot(
            (snapshot) => {
                const schools = FbUtils.queryToDocs(snapshot) as School[]
                // log.debug(`Schools in snapshot ${prettyJson(schools)}`)

                const sortedSchools = sortBy(schools, (school: School) =>
                    school.name.toLowerCase(),
                )
                this.schools = makeSubscriber(sortedSchools)
            },
            (error) => {
                log.debug(`Error in schools ${prettyJson(error)}`)
                this.schools = makeSubscriberError(error)
            },
        )
        return () => {
            unsubscribe()
            this.schools = makeSubscriber()
        }
    }

    subscribeToSchool = (id: string) => {
        if (this.school.subscribed) {
            return () => {}
        }
        log.debug(`Subscribe to school`)
        this.school = { loading: true, subscribed: true }
        const unsubscribe = schoolCollection()
            .doc(id)
            .onSnapshot(
                (snapshot) => {
                    const doc = FbUtils.queryDocToDoc(snapshot) as School
                    const curSchool: School = {
                        ...doc,
                        options: {
                            includesFifth: doc.options
                                ? doc.options.includesFifth
                                : false,
                            includesSixth: doc.options
                                ? doc.options.includesSixth
                                : false,
                        },
                    }
                    this.school = makeSubscriber(curSchool)
                },
                (error) => {
                    this.school = makeSubscriberError(error)
                },
            )
        return () => {
            unsubscribe()
            this.school = makeSubscriber()
        }
    }

    saveNewSchool = async () => {
        await schoolCollection().add(this.cleanSchool(this.createNewSchool!))
        this.schoolSaved = true
    }

    updateSchool = async () => {
        const toUpdate = this.cleanSchool(this.school.value!)
        log.debug(`Update school with id ${toUpdate.id}`)
        await schoolCollection().doc(toUpdate.id).set(toUpdate, { merge: true })
        this.schoolSaved = true
    }

    private cleanSchool = (school: School): School => {
        const { name, district, city, ...rest } = school
        return {
            name: name.trim(),
            district: district.trim(),
            city: city.trim(),
            ...rest,
        }
    }

    deleteSchool = async (): Promise<void> => {
        const batch = fbdb.batch()
        const schoolContainer = schoolCollection().doc(this.schoolId)
        batch.delete(schoolContainer)
        const userInviteContainer = await userInviteCollection()
            .where("schoolId", "==", this.schoolId)
            .get()
        userInviteContainer.forEach(function (doc) {
            batch.delete(doc.ref)
        })
        const teacherContainer = await portalUserCollection()
            .where("schoolId", "==", this.schoolId)
            .get()

        for (const teacher of teacherContainer.docs) {
            batch.delete(teacher.ref)
            const classContainer = await classCollection()
                .where("teacherId", "==", teacher.id)
                .get()

            for (const doc of classContainer.docs) {
                batch.delete(doc.ref)
                const classStudents = await studentCollection()
                    .where("classId", "==", doc.id)
                    .get()
                const classStudentsProgress = await activityProgressCollection()
                    .where("classId", "==", doc.id)
                    .get()
                const classStudentsResults = await studentResultsCollection()
                    .where("classId", "==", doc.id)
                    .get()
                classStudents.forEach(function (doc) {
                    batch.delete(doc.ref)
                })
                classStudentsProgress.forEach(function (doc) {
                    batch.delete(doc.ref)
                })
                classStudentsResults.forEach(function (doc) {
                    batch.delete(doc.ref)
                })
            }
        }

        try {
            await batch.commit()
            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    @computed
    get schoolId(): string | undefined {
        if (this.school.value == null) {
            return undefined
        }
        return this.school.value.id
    }
}

export const schoolStore = new SchoolStore()
