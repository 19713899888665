import { createStyles, makeStyles, Typography } from "@material-ui/core"
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { LinkButton } from "../../generic/atoms/LinkButton"
import { Loader } from "../../generic/atoms/Loader"
import { useGenericStyles } from "../../generic/GenericStyles"
import { TeachersTable } from "../../portal-users/organisms/TeachersTable"
import { RoutePaths } from "../../routing/RoutePaths"
import { schoolStore } from "../stores/SchoolStore"

const useStyles = makeStyles(() =>
    createStyles({
        schoolBar: {
            display: "flex",
        },
    }),
)

export const SchoolDetailsPage = observer(() => {
    const { grow, marginLeft } = useGenericStyles()
    const styles = useStyles()
    const { schoolId } = useParams()
    const { school } = schoolStore

    useEffect(() => {
        return schoolStore.subscribeToSchool(schoolId)
    }, [schoolId])

    if (school.loading) {
        return <Loader />
    }

    return (
        <div>
            <div className={styles.schoolBar}>
                <Typography variant={"h4"} className={grow}>
                    {school.value!.name}
                </Typography>
                <LinkButton
                    className={marginLeft}
                    to={RoutePaths.inviteTeacher(schoolId)}
                    variant={"contained"}
                    color={"primary"}
                >
                    Invite Teacher
                </LinkButton>
            </div>
            <TeachersTable schoolId={schoolId} />
        </div>
    )
})
