export interface Subscribable<T> extends Loadable<T> {
    subscribed: boolean
}

export interface Loadable<T> {
    value?: T
    loading: boolean
    error?: Error
}

export const makeSubscriber = <T>(value?: T): Subscribable<T> => ({
    loading: value == null, // loading is true if there is no value
    subscribed: value != null, // subscribed is true if there is a value
    value
})

export const makeSubscriberError = <T>(error: Error): Subscribable<T> => ({
    loading: false,
    subscribed: true,
    error
})

export const makeLoader = <T>(value?: T): Loadable<T> => ({
    loading: false,
    value
})

export const makeLoaderError = <T>(error: Error): Loadable<T> => ({
    loading: false,
    error
})
